import React, { useEffect, useRef, useState } from 'react';
import io from 'socket.io-client';
import { Col, Container, Row } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import moment, { Moment } from 'moment';
import { useToast } from '../../hooks/toast';
import Button from '../../components/dr-button';

import {
  ContainerInformationPix,
  TextInformationPix,
  ContainerPaymentWithCode,
  PaymentValuePix,
  TimeLimitForPayment,
  TimeDateLimitForPlayer,
  ButtonCopyCodePayment,
  InputCodePayment,
  ContainerCodeOption,
  ContainerTimePayment,
  NameButtonCopy,
} from './styled';

import { sleep, valueParam } from '../../utils/bibli';

import { PageSubtitle, PageTitle } from '../../styles/page-title';
import { formatValue } from '../../utils/formatNumbers';
import { msToClock } from '../../utils/formatDates';
import { useAuth } from '../../hooks/auth';
import DrCardLoading from '../../components/dr-card-loading';
import DrQRCode from '../../components/dr-qrcode';
import DrModal from '../../components/dr-modal';

interface pixProps {
  dateLimitPayment?: string;
  idconta_receber_pix_movto?: number;
  codePayment?: string;
  expericao?: number;
  dateCreatePix?: string;
  qrcodeImage?: string;
}

export interface SocketPix {
  socket?: any;
  idconta_receber_pix_movto?: number;
}

const PaymentPix: React.FC = () => {
  const history = useHistory();
  const { addToast } = useToast();
  const {
    requestAPI,
    sendError,
    attendance,
    applicationData,
    user,
    updateUser,
    setAttendanceData,
  } = useAuth();
  const idconta_receber_pix_movto = useRef<any>(null);
  const notCancelPix = useRef<any>(false);
  const interval = useRef<ReturnType<typeof setTimeout>>();

  const [socketPix, setSocketPix] = React.useState<SocketPix>({
    socket: undefined,
    idconta_receber_pix_movto: undefined,
  });

  const location = useLocation();
  const { values }: any = location.state;

  const difDate = useRef<any>();

  const [isModalPix, setModalPix] = useState(false);

  const [momentDate, setMomentDate] = useState<Moment>();
  const [isLoading, setLoading] = useState(true);
  const [paymentPixProperties, setPaymentPixProperties] = useState<pixProps>(
    {}
  );
  const [timer, setTimer] = useState('00:00:00');

  const isValidTimerPaymentPix = timer >= '00:00:00';

  const getSocketPix = () => {
    let socket;
    try {
      socket = io.connect(valueParam(applicationData, 292));
    } catch (err: any) {
      if (sendError) sendError(err);
    }

    return socket;
  };

  const cancelPixRoute = async () => {
    let cancelPix = true;
    setLoading(true);

    if (idconta_receber_pix_movto.current) {
      try {
        await requestAPI({
          method: 'PUT',
          url: `/pixCancelarPixMovto/${idconta_receber_pix_movto.current}`,
        });
      } catch (err: any) {
        if (err.message.includes('pago')) {
          cancelPix = false;
        }
        sendError(err);
      } finally {
        setLoading(false);
      }
    }

    return cancelPix;
  };

  const cancelPaymentPix = async (messageTimeException: boolean) => {
    notCancelPix.current = true;
    socketPix.socket?.close();
    setSocketPix({ socket: undefined, idconta_receber_pix_movto: undefined });

    const cancelPix = await cancelPixRoute();

    if (cancelPix) {
      history.push('/payment');

      if (!isValidTimerPaymentPix && messageTimeException)
        addToast({
          type: 'error',
          title: 'Ops!',
          description: 'Tempo de pagamento do pix expirado!',
        });

      return null;
    }

    history.push('/dashboard');
    addToast({
      type: 'success',
      title: 'Pagamento Aprovado',
      description: `O pagamento Pix já foi processado com sucesso e não pode ser cancelado.`,
    });

    return null;
  };

  const setTimeDurationPix = () => {
    interval.current = setInterval(() => {
      const timeLeftDuration = moment.duration(
        momentDate?.diff(moment().add(difDate.current))
      );

      const timeLeftInMilliSec = timeLeftDuration.asMilliseconds();

      const tmeLeftForDrCall = msToClock(timeLeftInMilliSec);

      if (tmeLeftForDrCall < '00:00:00') {
        setTimer('');
        clearInterval(interval.current);
        return;
      }
      setTimer(tmeLeftForDrCall);
    }, 1000);
  };

  const copyCodeForPaymentPix = (codePaymentPix) => {
    navigator.clipboard.writeText(codePaymentPix);
    addToast({
      type: 'success',
      title: 'O código para pagamento pix foi copiado com sucesso!',
    });
  };

  const getQrCodePix = async () => {
    try {
      const body = {
        idagenda_consulta:
          attendance && attendance.idagenda_consulta
            ? attendance.idagenda_consulta
            : null,
        idvoucher: values.idvoucher,
        ds_observacao: JSON.stringify(attendance),
        vl_desconto: values.vl_desconto,
        vl_servico: values.vl_produto,
        idpessoa_fisica: user.idpessoa_fisica,
        vlr_pix: values.vl_pagto,
      };

      const responseQrCodePix = await requestAPI({
        method: 'POST',
        url: '/pixCobranca',
        body,
      });

      if (!responseQrCodePix) {
        sendError('Resposta do responseQrCodePix VAZIA!');
        addToast({
          type: 'error',
          title: 'Ops!',
          description: 'Ocorreu um erro ao tentar gerar o QRCODE do pix',
        });
        history.push('/types-care');
        return;
      }

      setPaymentPixProperties({
        ...paymentPixProperties,
        idconta_receber_pix_movto: responseQrCodePix.idconta_receber_pix_movto,
        codePayment: responseQrCodePix.qrcode,
        qrcodeImage: responseQrCodePix.qrcodeImage,
        expericao: responseQrCodePix.expericao,
        dateCreatePix: responseQrCodePix.dt_criacao,
        dateLimitPayment: moment(responseQrCodePix.dt_criacao)
          .add(responseQrCodePix.expericao, 'm')
          .format('DD/MM/YYYY HH:mm'),
      });

      idconta_receber_pix_movto.current =
        responseQrCodePix.idconta_receber_pix_movto;

      setSocketPix({
        ...socketPix,
        idconta_receber_pix_movto: responseQrCodePix.idconta_receber_pix_movto,
      });

      setMomentDate(
        moment(responseQrCodePix.dt_criacao).add(
          responseQrCodePix.expericao,
          'm'
        )
      );

      const timeLeftDuration = moment.duration(
        moment(responseQrCodePix.dt_criacao)
          .add(responseQrCodePix.expericao, 'm')
          .diff(moment(responseQrCodePix.dt_criacao))
      );
      const timeLeftInMilliSec = timeLeftDuration.asMilliseconds();
      const tmeLeftForDrCall = msToClock(timeLeftInMilliSec);

      difDate.current = moment(responseQrCodePix.dt_criacao).diff(moment());
      setTimer(tmeLeftForDrCall);
    } catch (err) {
      sendError(err);
      addToast({
        type: 'error',
        title: 'Ops!',
        description: 'Ocorreu um erro ao tentar gerar o QRCODE do pix',
      });
      history.push('/types-care');
    } finally {
      setLoading(false);
    }
  };

  const pixPaymentAprroved = async (paySuccess) => {
    if (
      idconta_receber_pix_movto.current !== paySuccess.idconta_receber_pix_movto
    )
      return;

    const localStorageDatas = Object.assign(attendance, {
      idconta_receber_cartao_movto: paySuccess.idconta_receber_cartao_movto,
      idcontrato: paySuccess.dadosAssinaturaPln
        ? paySuccess.dadosAssinaturaPln.assinaturas_pln.idcontrato
        : undefined,
      paymentPix: true,
      assinaturas_pln: paySuccess.dadosAssinaturaPln
        ? paySuccess.dadosAssinaturaPln.assinaturas_pln
        : undefined,
      vl_total_servico: values.vl_pagto,
    });

    if (paySuccess.dadosAssinaturaPln) {
      const userUp = user;
      userUp.ds_produto =
        paySuccess.dadosAssinaturaPln?.assinaturas_pln?.produto_ser?.ds_produto;
      updateUser({ ...userUp });
    }

    paySuccess.paymentPix = true;

    await setAttendanceData(localStorageDatas, true);

    socketPix.socket?.close();
    setSocketPix({ socket: undefined, idconta_receber_pix_movto: undefined });

    setLoading(true);
    await sleep(1500);
    notCancelPix.current = true;
    setLoading(false);

    history.push({
      pathname: `/payment-made`,
      state: paySuccess,
    });
  };

  const connectSocketSucess = (socket) => {
    setSocketPix({ ...socketPix, socket });

    socket.on('pixPaymentApproved', async (pixPropsApproved: any) => {
      await pixPaymentAprroved(pixPropsApproved);
    });
  };

  const addEventsPixSocket = () => {
    const socket = getSocketPix();

    if (socket) connectSocketSucess(socket);
  };

  useEffect(() => {
    getQrCodePix();
    addEventsPixSocket();

    const destroyUseEffect = async () => {
      if (socketPix && socketPix.socket) socketPix.socket?.close();
      setSocketPix({
        socket: undefined,
        idconta_receber_pix_movto: undefined,
      });
      if (!notCancelPix.current) {
        const cancelPix = await cancelPixRoute(); // Aguarde a conclusão de cancelPixRoute

        if (!cancelPix) {
          history.push('/dashboard');
          addToast({
            type: 'success',
            title: 'Pagamento Aprovado',
            description: `O pagamento via Pix já foi confirmado e não pode ser cancelado!`,
          });
        }
      }
    };

    return () => {
      destroyUseEffect();
    };
  }, []);

  useEffect(() => {
    setTimeDurationPix();
    if (!isValidTimerPaymentPix) {
      setModalPix(true);
    }

    return () => clearInterval(interval.current);
  }, [timer]);

  return (
    <>
      <></>
      {isLoading ? (
        <DrCardLoading />
      ) : (
        <Container>
          <PageTitle style={{ margin: '1.5rem', fontSize: '1.4rem' }}>
            Aguardando Pagamento
          </PageTitle>

          <ContainerInformationPix>
            <PageSubtitle color="#ed145b" style={{ fontSize: '1.2rem' }}>
              Você escolheu o método de pagamento via PIX.Escaneie o QR CODE
              para pagar.
            </PageSubtitle>

            <TextInformationPix>
              1. Acesse o Internet Banking ou app de pagamentos de sua
              preferência.
            </TextInformationPix>
            <TextInformationPix>2. Escolha o pagar via PIX.</TextInformationPix>
            <TextInformationPix>
              3. Escaneie o código abaixo.
            </TextInformationPix>
            <DrQRCode url={paymentPixProperties.qrcodeImage} />
          </ContainerInformationPix>

          <ContainerPaymentWithCode>
            <PageSubtitle color="#ed145b" style={{ fontSize: '1.2rem' }}>
              Ou copie o código abaixo para fazer o pagamento.
            </PageSubtitle>
            <TextInformationPix>
              Escolha pagar via PIX no seu Internet Banking.Depois Cole o
              seguinte código.
            </TextInformationPix>

            <ContainerCodeOption>
              <InputCodePayment
                type="text"
                value={paymentPixProperties.codePayment}
                disabled
              />

              <ButtonCopyCodePayment
                fontSize={24}
                onClick={() => {
                  copyCodeForPaymentPix(paymentPixProperties.codePayment);
                }}
              >
                <NameButtonCopy>Copiar</NameButtonCopy>
              </ButtonCopyCodePayment>
            </ContainerCodeOption>
          </ContainerPaymentWithCode>

          <Row className="col-xl-12 row-btn mt-3 m-0">
            <TextInformationPix
              style={{ fontWeight: 'bold' }}
              color="rgb(179, 179, 179)"
            >
              Pagamento Total :
            </TextInformationPix>
            <PaymentValuePix style={{ fontWeight: 'bold', marginTop: 1 }}>
              {values.ds_forma} {formatValue(values.vl_pagto || '0')}
            </PaymentValuePix>
          </Row>

          <Row className="col-xl-12 row-btn m-0">
            <TextInformationPix
              style={{ fontWeight: 'bold' }}
              color="rgb(179, 179, 179)"
            >
              Limite para Pagamento :
            </TextInformationPix>

            <ContainerTimePayment>
              <TimeLimitForPayment style={{ fontWeight: 'bold', height: 4 }}>
                {timer}
              </TimeLimitForPayment>

              <TimeDateLimitForPlayer>
                Válido até {paymentPixProperties.dateLimitPayment}{' '}
              </TimeDateLimitForPlayer>
            </ContainerTimePayment>
          </Row>

          <Row className="col-xl-12 justify-content-center row-btn m-0">
            <Col md={12} xl={4}>
              <Button
                type="button"
                title="Cancelar Pagamento"
                color="#ed145b"
                onClick={() => cancelPaymentPix(false)}
              />
            </Col>
          </Row>
          <DrModal
            controllerModal={[isModalPix, setModalPix]}
            title="Pix Expirado!"
            buttons={{
              cancel: {
                title: 'Voltar',
                onClick: () => {
                  setModalPix(false);
                  cancelPaymentPix(true);
                },
              },
            }}
            content={<h4>Seu tempo para pagamento deste Pix expirou!</h4>}
          />
        </Container>
      )}
    </>
  );
};

export default PaymentPix;
