"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.timeText = exports.timeTextMask = exports.cvc = exports.cvcMask = exports.cardDate = exports.cardDateMask = exports.cardNumber = exports.cardNumberMask = exports.cpfMask = exports.date = exports.dateMask = exports.currency = exports.currencyMask = exports.cep = exports.cepMask = exports.phoneNumber = exports.phoneNumberMask = exports.nameInput = exports.nameInputMask = exports.emailInput = exports.emailMask = exports.onlyNumbers = exports.onlyNumbersMask = void 0;
function onlyNumbersMask(value) {
    let result = value;
    if (result) {
        result = result.replace(/[^0-9]/g, '');
    }
    return result;
}
exports.onlyNumbersMask = onlyNumbersMask;
function onlyNumbers(value) {
    let result = value;
    if (result) {
        result = result.replace(/[^0-9]/g, '');
    }
    return result;
}
exports.onlyNumbers = onlyNumbers;
function emailMask(value) {
    let result = value;
    if (result) {
        result = result.trim();
    }
    return result;
}
exports.emailMask = emailMask;
function emailInput(e) {
    e.currentTarget.maxLength = 40;
    let { value } = e.currentTarget;
    value = value.trim();
    e.currentTarget.value = value;
    return e;
}
exports.emailInput = emailInput;
function nameInputMask(value) {
    let result = value;
    if (result) {
        result = result.replace(/[^a-zà-úA-ZÀ-Ú ']/g, '');
    }
    return result;
}
exports.nameInputMask = nameInputMask;
function nameInput(e) {
    e.currentTarget.maxLength = 40;
    let { value } = e.currentTarget;
    value = value.replace(/[^a-zà-úA-ZÀ-Ú ']/g, '');
    e.currentTarget.value = value;
    return e;
}
exports.nameInput = nameInput;
function phoneNumberMask(value) {
    let result = value;
    if (result) {
        result = result.replace(/\D/g, '');
        result = result.replace(/^(\d{2})(\d)/g, '($1) $2');
        result = result.replace(/(\d)(\d{4})$/, '$1-$2');
        return result;
    }
    return result;
}
exports.phoneNumberMask = phoneNumberMask;
function phoneNumber(e) {
    e.currentTarget.maxLength = 15;
    e.currentTarget.value = phoneNumberMask(e.currentTarget.value) || '';
    return e;
}
exports.phoneNumber = phoneNumber;
function cepMask(value) {
    let result = value;
    if (result) {
        result = result.replace(/\D/g, '');
        result = result.replace(/^(\d{5})(\d)/, '$1-$2');
    }
    return result;
}
exports.cepMask = cepMask;
function cep(e) {
    e.currentTarget.maxLength = 9;
    e.currentTarget.value = cepMask(e.currentTarget.value) || '';
    return e;
}
exports.cep = cep;
function currencyMask(value) {
    let result = value;
    if (result) {
        result = result.replace(/\D/g, '');
        result = result.replace(/(\d)(\d{2})$/, '$1, $2');
        result = result.replace(/(?=(\d{3})+(\D))\B/g, '.');
    }
    return result;
}
exports.currencyMask = currencyMask;
function currency(e) {
    e.currentTarget.value = currencyMask(e.currentTarget.value) || '';
    return e;
}
exports.currency = currency;
function dateMask(value) {
    let result = value;
    if (result) {
        result = result.replace(/\D/g, '');
        result = result.replace(/^(\d{2})$/g, '$1');
        result = result.replace(/^(\d{2})(\d{1,2})$/g, '$1/$2');
        result = result.replace(/^(\d{2})(\d{2})(\d{1,4})$/g, '$1/$2/$3');
    }
    return result;
}
exports.dateMask = dateMask;
function date(e) {
    e.currentTarget.maxLength = 10;
    if (e.keyCode === 8) {
        e.currentTarget.value = '';
    }
    e.currentTarget.value = dateMask(e.currentTarget.value) || '';
    return e;
}
exports.date = date;
function cpfMask(value) {
    let result = value;
    if (result) {
        result = result
            .replace(/\D/g, '')
            .replace(/(\d{3})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d{1,2})/, '$1-$2')
            .replace(/(-\d{2})\d+?$/, '$1');
    }
    return result;
}
exports.cpfMask = cpfMask;
function cardNumberMask(value) {
    let result = value;
    if (result) {
        result = result.replace(/^(\d{2})(\d)/, '$1/$2');
        result = result.replace(/\D/g, '');
        result = result.replace(/^(\d{4})(\d)/g, '$1 $2');
        result = result.replace(/^(\d{4})\s(\d{4})(\d)/g, '$1 $2 $3');
        result = result.replace(/^(\d{4})\s(\d{4})\s(\d{4})(\d)/g, '$1 $2 $3 $4');
    }
    return result;
}
exports.cardNumberMask = cardNumberMask;
function cardNumber(e) {
    e.currentTarget.maxLength = 19;
    e.currentTarget.value = cardNumberMask(e.currentTarget.value) || '';
    return e;
}
exports.cardNumber = cardNumber;
function cardDateMask(value) {
    let result = value;
    if (result) {
        result = result.replace(/\D/g, '');
        result = result.replace(/^(\d{2})(\d)/, '$1/$2');
    }
    return result;
}
exports.cardDateMask = cardDateMask;
function cardDate(e) {
    e.currentTarget.maxLength = 5;
    e.currentTarget.value = cardDateMask(e.currentTarget.value) || '';
    return e;
}
exports.cardDate = cardDate;
function cvcMask(value) {
    let result = value;
    if (result) {
        result = result.replace(/\D/g, '');
        result = result.replace(/[^0-9]/g, '');
    }
    return result;
}
exports.cvcMask = cvcMask;
function cvc(e) {
    e.currentTarget.maxLength = 4;
    e.currentTarget.value = cvcMask(e.currentTarget.value) || '';
    return e;
}
exports.cvc = cvc;
function timeTextMask(value) {
    let result = value;
    if (result) {
        result = result.replace(/\D/g, '');
        result = result.replace(/^([0-2][1-9])$/, '$1');
        result = result.replace(/^([0-2][0-9])(\d{1,})$/, '$1:$2');
    }
    return result;
}
exports.timeTextMask = timeTextMask;
function timeText(e) {
    e.currentTarget.maxLength = 5;
    e.currentTarget.value = timeTextMask(e.currentTarget.value) || '';
    return e;
}
exports.timeText = timeText;
