import styled from 'styled-components';

export const ContainerShowSpecialities = styled.div`
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  -webkit-box-shadow: 0 10px 20px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 0 10px 20px 1px rgb(0 0 0 / 5%);
  margin-top: 30px;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
`;

export const Container = styled.div`
  cursor: pointer;
  background-color: white;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  margin-bottom: 10px;
  min-width: 60%;
  @media screen and (max-width: 770px) {
    min-width: 100%;
  }
  border: solid 2px #3f56a5;
`;
export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Distribui os elementos ao longo da largura */
  align-items: center; /* Alinha os elementos verticalmente ao centro */
  width: 100%; /* Ajuste conforme necessário */
`;
export const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1; /* Faz com que ocupe o espaço disponível */
  text-align: center;
  font-size: 1.3rem;
  font-weight: 700;
  color: black;
`;
export const Title = styled.h4`
  margin: 0;
  color: rgb(63, 86, 165);
`;

export const ContainerTimeButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #60bb46; /* Ajuste a cor de fundo conforme necessário */
  border-radius: 25px;
  padding: 10px 20px;
`;
