import React, { useState, useCallback, useRef, ChangeEvent } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { onlyNumbers } from 'bibli-front';
import { useHistory, useLocation } from 'react-router-dom';
import { NumberContainer, PictureText } from './styled';
import {
  UserMember,
  useAuth,
  User,
  MemberType,
  AuthContextData,
  CepValues,
} from '../../hooks/auth';
import Input from '../dr-input';
import Button from '../dr-button';
import Checkbox from '../dr-input-checkbox';
import DrSelect from '../dr-input-select';
import DrLoginSocial from '../dr-login-social';
import { BoxPicture } from '../../styles/box-picture';
import { BoxPictureImg } from '../../styles/box-picture_img';
import { CardBlank } from '../../styles/card-blank';
import { PageTitle } from '../../styles/page-title';
import { Fields } from '../../styles/fields';
import { BoxLoginBody } from '../../styles/box-login_body';
import { ToastContextData, useToast } from '../../hooks/toast';
import {
  isValidCPF,
  isValidDateToRegister,
  isFutureDate,
} from '../../utils/validations';
import {
  validateNameComplete,
  setDataNull,
  verifyErrorYup,
  handleConcatUserData,
  formatDateISO,
  valueParam,
} from '../../utils/bibli';
import { handleDifferenceYears, personAge } from '../../utils/formatDates';
import DropZone from '../dr-dropZone';

import DrButtonLoading from '../dr-button-loading';
import DrDatalist from '../dr-input-datalist';

interface UserProps {
  method: 'GET' | 'POST' | 'PUT' | 'DELETE';
  type: 'family' | 'user';
  url: string;
  userData?: any;
}

interface AddressProps {
  userData?: Partial<User | MemberType>;
  setUserData?: React.Dispatch<
    React.SetStateAction<Partial<User | MemberType>>
  >;
  type?: 'family' | 'user';
  formRef: React.RefObject<FormHandles>;
  sendError: AuthContextData['sendError'];
  addToast: ToastContextData['addToast'];
  handleAddress: AuthContextData['handleAddress'];
  parentage: string;
  method: string;
  fieldsMandatory: any;
}

interface ContactProps {
  type?: 'family' | 'user';
  userData?: Partial<User | MemberType>;
  formRef: React.RefObject<FormHandles>;
  sendError: AuthContextData['sendError'];
  parentage: string;
  fieldsMandatory: any;
  toggleWppNotification?: (param: Partial<User | MemberType>) => void;
}

const labelCheckBox = (param) => <>{param}</>;

const defaultArray = [{ label: 'Selecione', value: '' }];

const CardContact: React.FC<ContactProps> = (Props: ContactProps) => {
  const { userData, type, parentage, fieldsMandatory, toggleWppNotification } =
    Props;

  const [ieWhatsApp, setIeWhatsApp] = useState(userData?.ie_whatsapp || 'N');

  const handleGetNotificationData = useCallback(
    (ev) => {
      const { name, checked } = ev.currentTarget;
      if (name === 'ie_whatsapp') {
        if (toggleWppNotification)
          toggleWppNotification({
            ...userData,
            ie_whatsapp: checked ? 'S' : 'N',
            ie_notifica_whatsapp: checked ? 'S' : 'N',
          });
      }
    },
    [userData]
  );

  return (
    <Container>
      <CardBlank className="pt-3 pb-3 mt-3">
        <PageTitle>Dados de Contato</PageTitle>
        <Fields>
          <Row className="m-0">
            <Col md={12} xl={6}>
              <Input
                icon
                name="ds_email"
                label={`E-mail${
                  type === 'family' && parentage ? ` - ${parentage}` : ''
                }`}
                mandatory={fieldsMandatory('ds_email')}
                placeholder="Digite o e-mail"
                value={userData?.ds_email}
                customType="email"
                validate
                autoComplete="new-password"
              />
            </Col>
            <Col md={12} xl={6}>
              <Input
                icon
                mandatory={fieldsMandatory('confirmar_email')}
                name="confirmar_email"
                label={`Confirmação de e-mail${
                  type === 'family' && parentage ? ` - ${parentage}` : ''
                }`}
                placeholder="Confirme o e-mail"
                value={userData?.ds_email}
                customType="email"
                validate
                autoComplete="new-password"
              />
            </Col>
            <Col md={12} xl={6}>
              <Input
                icon
                name="nr_celular"
                label={`Celular ou Telefone${
                  type === 'family' && parentage ? ` - ${parentage}` : ''
                }`}
                value={userData?.nr_celular}
                customType="phoneNumber"
                validate
                mandatory={fieldsMandatory('nr_celular')}
              />
            </Col>
            <Col md={12} xl={6} className="d-flex align-items-center">
              <Checkbox
                noMargin
                name="ie_whatsapp"
                label="Este número é WhatsApp?"
                initialValue={userData?.ie_whatsapp}
                controller={[ieWhatsApp, setIeWhatsApp]}
                onChange={(ev) => handleGetNotificationData(ev)}
              />
            </Col>
          </Row>
        </Fields>
      </CardBlank>
    </Container>
  );
};

const CardAddress: React.FC<AddressProps> = (Props: AddressProps) => {
  const {
    userData,
    handleAddress,
    type,
    sendError,
    addToast,
    formRef,
    parentage,
    method,
    fieldsMandatory,
  } = Props;

  const [enableInputs, setEnableInputs] = useState({
    enableBairro: false,
    enableCity: false,
    enableUf: false,
    modifiedCity: false,
  });

  const [loadingInputs, setLoadingInputs] = useState({
    loadingBairro: false,
    loadingCity: false,
    loadingUf: false,
  });

  const [disableInputNumber, setDisableInputNumber] = useState<any>(
    () => userData?.nr_endereco === ''
  );

  const clearValueRef = useRef<boolean>(false);
  const [arrayCity, setArrayCity] = useState<any[]>(() => {
    if (userData?.ds_municipio) {
      const cityData: any = [];
      cityData.push({
        label: userData?.ds_municipio,
        value: userData?.ds_municipio,
      });

      return cityData;
    }

    return defaultArray;
  });

  const [street, setStreet] = useState(userData?.ds_logradouro);

  const [arrayUF, setArrayUF] = useState<any[]>(() => {
    if (userData?.sg_uf) {
      const uf: any = [];
      uf.push({
        label: userData?.sg_uf,
        value: userData?.sg_uf,
      });
      return uf;
    }
    return defaultArray;
  });

  const [arrayAddress, setArrayAddress] = useState<any[]>(() => {
    if (userData?.ds_bairro) {
      const bairro: any = [];
      bairro.push({
        label: userData?.ds_bairro,
        value: userData?.ds_bairro,
      });
      return bairro;
    }
    return defaultArray;
  });

  const handleStreetInput = useCallback((val: string) => {
    setStreet(val);
  }, []);

  function clearAddressFields() {
    setArrayUF(defaultArray);
    setArrayCity(defaultArray);
    formRef.current?.clearField('ds_bairro');
    setStreet('');
    clearValueRef.current = true;
  }

  const handleCepInput = async (value) => {
    const val: any = onlyNumbers(value);
    try {
      if (!val.length) {
        clearAddressFields();
      }
      if (val.length > 7) {
        clearValueRef.current = false;
        setLoadingInputs({
          loadingBairro: true,
          loadingCity: true,
          loadingUf: true,
        });

        setEnableInputs({
          enableBairro: false,
          enableCity: false,
          enableUf: false,
          modifiedCity: false,
        });
        const addressArray = await handleAddress({
          cep: val,
        });

        if (addressArray.length) {
          handleStreetInput(addressArray[0].ds_logradouro);

          setArrayUF([
            {
              label: addressArray[0].sg_uf,
              value: addressArray[0].sg_uf,
            },
          ]);
          setArrayCity([
            {
              label: addressArray[0].ds_municipio,
              value: addressArray[0].ds_municipio,
            },
          ]);

          setArrayAddress([
            {
              label: addressArray[0].ds_bairro,
              value: addressArray[0].ds_bairro,
            },
          ]);
          formRef.current?.setFieldValue('sg_uf', addressArray[0].sg_uf);
          formRef.current?.setFieldValue(
            'ds_municipio',
            addressArray[0].ds_municipio
          );
          formRef.current?.setFieldValue(
            'ds_bairro',
            addressArray[0].ds_bairro
          );
        } else {
          setArrayUF((prev) => [...defaultArray]);
          setLoadingInputs((prev) => ({ ...prev, loadingUf: true }));
          const resp = await handleAddress({});

          const selectFiltered: Array<{ label: string; value: string }> =
            resp.map((ufs: CepValues) => ({
              label: ufs.sg_uf,
              value: ufs.sg_uf,
            }));
          setArrayUF((prev) => [...prev, ...selectFiltered]);
          setArrayCity(defaultArray);
          setArrayAddress(defaultArray);
          handleStreetInput('');
          formRef.current?.setFieldValue('ds_logradouro', '');
          formRef.current?.setFieldValue('ds_bairro', '');

          setEnableInputs({
            enableUf: true,
            enableBairro: true,
            enableCity: true,
            modifiedCity: false,
          });
        }
      }
    } catch (err: any) {
      sendError(err);
      addToast({
        type: 'error',
        title: 'Ops!',
        description: `${err.message}`,
      });
    } finally {
      setLoadingInputs({
        loadingCity: false,
        loadingBairro: false,
        loadingUf: false,
      });
    }
  };

  const handleGetSelectData = React.useCallback(
    async (ev: any) => {
      const { name, value, selectedIndex } = ev.currentTarget;
      const obj: any = {
        nameAttrib: null,
        idAttrib: null,
        regSelected: null,
        params: {},
      };

      switch (name) {
        case 'ds_municipio':
          obj.nameAttrib = 'ds_bairro';
          obj.idAttrib = 'idbairro';
          if (arrayCity.length) obj.regSelected = arrayCity[selectedIndex];
          obj.params.city = obj.regSelected.id;
          setLoadingInputs((prev) => ({ ...prev, loadingBairro: true }));
          setEnableInputs((prev) => ({
            ...prev,
            enableBairro: true,
            modifiedCity: true,
          }));

          break;
        case 'sg_uf':
          obj.nameAttrib = 'ds_municipio';
          obj.idAttrib = 'idmunicipio';
          obj.params.uf = value;
          setLoadingInputs((prev) => ({ ...prev, loadingCity: true }));

          setEnableInputs((prev) => ({
            ...prev,
            enableBairro: false,
            enableCity: false,
          }));
          break;
        default:
          return;
      }
      let resp;
      try {
        resp = await handleAddress(obj.params);
      } catch (err: any) {
        sendError(err);
        addToast({
          type: 'error',
          title: 'Ops!',
          description: `${err.message}`,
        });
      } finally {
        setEnableInputs((prev) => ({
          ...prev,
          enableBairro: true,
          enableCity: true,
        }));

        setLoadingInputs((prev) => ({
          ...prev,
          loadingCity: false,
          loadingBairro: false,
        }));
      }

      const arrayFiltered = resp.map((result: any) => ({
        label: result[obj.nameAttrib],
        value: result[obj.nameAttrib],
        id: result[obj.idAttrib || obj.nameAttrib],
      }));
      arrayFiltered.unshift(defaultArray[0]);

      if (name === 'sg_uf') {
        setArrayCity(defaultArray);
        setArrayCity(arrayFiltered);
        setArrayAddress(defaultArray);
        formRef.current?.setFieldValue('ds_bairro', '');
      }
      if (name === 'ds_municipio') {
        formRef.current?.setFieldValue('ds_bairro', '');
        setArrayAddress(arrayFiltered);
      }
    },
    [addToast, arrayCity, handleAddress, sendError]
  );

  return (
    <Container>
      <CardBlank className="pt-3  mt-3">
        <PageTitle>Dados de Endereço</PageTitle>
        <Fields>
          <Row className="m-0">
            <Col md={12} xl={3}>
              <Input
                mandatory={fieldsMandatory('cep')}
                icon
                name="cep"
                label={`CEP${
                  type === 'family' && parentage ? ` - ${parentage}` : ''
                }`}
                placeholder="00000-000"
                value={userData?.cep}
                getValue={(val) => handleCepInput(val)}
                customType="cep"
              />
            </Col>
            <Col md={12} xl={3}>
              <DrSelect
                mandatory={fieldsMandatory('cep')}
                name="sg_uf"
                disabled={!enableInputs.enableUf}
                loading={loadingInputs.loadingUf}
                options={arrayUF}
                selectTitle={`UF${
                  type === 'family' && parentage ? ` - ${parentage}` : ''
                }`}
                value={userData?.sg_uf}
                onChange={(ev) => handleGetSelectData(ev)}
              />
            </Col>
            <Col md={12} xl={3}>
              <DrSelect
                mandatory={fieldsMandatory('cep')}
                name="ds_municipio"
                disabled={!enableInputs.enableCity}
                loading={loadingInputs.loadingCity}
                options={arrayCity}
                selectTitle={`Cidade${
                  type === 'family' && parentage ? ` - ${parentage}` : ''
                }`}
                onChange={(ev) => handleGetSelectData(ev)}
                value={userData?.ds_municipio}
              />
            </Col>
            <Col md={12} xl={3}>
              <DrDatalist
                mandatory={fieldsMandatory('cep')}
                name="ds_bairro"
                disabled={!enableInputs.enableBairro}
                loading={loadingInputs.loadingBairro}
                options={arrayAddress}
                selectTitle={`Bairro${
                  type === 'family' && parentage ? ` - ${parentage}` : ''
                }`}
                placeholder="Digite ou selecione o bairro"
                value={userData?.ds_bairro}
              />
            </Col>
            <Col md={12} xl={3}>
              <NumberContainer>
                <Input
                  name="nr_endereco"
                  mandatory={
                    disableInputNumber === false && fieldsMandatory('cep')
                  }
                  label={`Número${
                    type === 'family' && parentage ? ` - ${parentage}` : ''
                  }`}
                  placeholder={disableInputNumber ? 'S/N' : 'Digite o número'}
                  disabled={disableInputNumber}
                  clear={clearValueRef.current === true}
                  value={userData?.nr_endereco}
                  customType="numero"
                  validate
                />

                <Checkbox
                  name="semnumero"
                  noMargin
                  withPadding
                  label="S/N"
                  initialValue={disableInputNumber ? 'S' : 'N'}
                  onChange={(event) => {
                    setDisableInputNumber(event.currentTarget.checked);
                    clearValueRef.current = !clearValueRef.current;
                    return true;
                  }}
                />
              </NumberContainer>
            </Col>
            <Col md={12} xl={6}>
              <Input
                mandatory={fieldsMandatory('ds_logradouro')}
                name="ds_logradouro"
                label={`Endereço${
                  type === 'family' && parentage ? ` - ${parentage}` : ''
                }`}
                placeholder="Digite o endereço"
                value={street}
                getValue={(val) => handleStreetInput(val)}
                customType="logradouro"
                clear={!street?.length}
              />
            </Col>

            <Col md={12} xl={3}>
              <Input
                mandatory={
                  disableInputNumber === true && fieldsMandatory('cep')
                }
                name="ds_complemento"
                label={`Complemento${
                  type === 'family' && parentage ? ` - ${parentage}` : ''
                }`}
                placeholder="Quadra, Lote, Apto..."
                value={userData?.ds_complemento}
                validate
                customType="complemento"
              />
            </Col>
          </Row>
        </Fields>
      </CardBlank>
    </Container>
  );
};

const UserData: React.FC<UserProps> = ({
  userData,
  method,
  type,
  url,
}: UserProps) => {
  const formRef = useRef<FormHandles>(null);
  const firstTime = useRef(false);
  const location: any = useLocation();

  const { addToast } = useToast();
  const {
    requestAPI,
    updateUser,
    getDomainsData,
    sendError,
    handleAddress,
    applicationData,
    user,
  } = useAuth();
  const history = useHistory();
  const pictureRef = React.useRef<HTMLDivElement>(null);

  const fieldsMandatory = useCallback(
    (field_name) => {
      let arrayRequirements = [];
      let result;
      const paramsUser = valueParam(applicationData, 209);
      const paramsFamily = valueParam(applicationData, 214);
      if (type === 'user' && paramsUser) {
        arrayRequirements = paramsUser.split(',');
        result = arrayRequirements.find((attr) => attr === field_name);
      } else if (type === 'family' && paramsFamily) {
        arrayRequirements = paramsFamily.split(',');
        result = arrayRequirements.find((attr) => attr === field_name);
      }
      return !!result;
    },
    [applicationData?.valores_parametros]
  );

  function memberMajority() {
    if (
      userDataX?.dt_nascimento &&
      userDataX?.dt_nascimento.length >= 10 &&
      personAge(formatDateISO(userDataX?.dt_nascimento)) > 15
    ) {
      return true;
    }
    return false;
  }

  const schema = Yup.object()
    .default(null)
    .nullable()
    .shape(
      {
        ie_grau_parentesco: Yup.string()
          .trim()
          .test({
            message: 'Necessário informar o grau parentesco',
            test: (val) => !(fieldsMandatory('ie_grau_parentesco') && !val),
          })
          .nullable(true),
        nm_pessoa: Yup.string()
          .trim()
          .test({
            message: 'É necessário o nome completo',
            test: (val) => {
              if (val) return validateNameComplete(val);

              return true;
            },
          })
          .test({
            message: 'O nome completo é obrigatório',
            test: (val) => !(fieldsMandatory('nm_pessoa') && !val),
          })
          .nullable(true),
        nm_social: Yup.string()
          .test({
            message: 'Nome social é obrigatório',
            test: (val) => !(fieldsMandatory('nm_social') && !val),
          })
          .trim()
          .test({
            message: 'Nome inválido',
            test: (val) => {
              if (val) {
                return /[a-zzéúíóáèùìòàõãñêûîôâëyüïöäA-ZÉÚÍÓÁÈÙÌÒÀÕÃÑÊÛÎÔÂËYÜÏÖÄ ]{2,}/.test(
                  val
                );
              }
              return true;
            },
          })
          .nullable(true),
        ie_sexo: Yup.string()
          .test({
            message: 'Necessário informar o gênero',
            test: (val) => !(fieldsMandatory('ie_sexo') && !val),
          })
          .nullable(true)
          .trim(),
        dt_nascimento: Yup.string()
          .test({
            message: 'Data de nascimento obrigatório',
            test: (val) => !(fieldsMandatory('dt_nascimento') && !val),
          })
          .matches(
            // eslint-disable-next-line no-useless-escape
            /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/g,
            'Data inválida'
          )
          .test({
            message:
              type === 'user'
                ? 'O titular da conta deve ter mais que 16 anos'
                : 'Data maior que o limite de 13 anos',
            test: (val) => {
              if (type === 'user') {
                return handleDifferenceYears(val, 15);
              }

              return true;
            },
          })
          .test({
            message: 'Data inválida',
            test: (value) =>
              fieldsMandatory('dt_nascimento') === false ||
              (isValidDateToRegister(value) && !isFutureDate(value)),
          })
          .nullable(true)
          .trim(),
        nm_mae: Yup.string()
          .test({
            message: 'Necessário informar o nome da mãe',
            test: (val) => !(fieldsMandatory('nm_mae') && !val),
          })
          .trim()
          .matches(/[A-zÀ-ü] [A-zÀ-ü]/, 'Necessário o nome completo da mãe')
          .nullable(true),
        nm_pai: Yup.string()
          .test({
            message: 'Necessário o nome completo do pai',
            test: (val) => !(fieldsMandatory('nm_pai') && !val),
          })
          .nullable(true)
          .matches(/[A-zÀ-ü] [A-zÀ-ü]/, {
            message: 'Necessário o nome completo do pai',
            excludeEmptyString: true,
          })
          .trim(),
        nr_cpf: Yup.string()
          .test({
            message: 'Necessário informar o CPF',
            test: (val) => !(fieldsMandatory('nr_cpf') && !val),
          })
          .trim()
          .test('cpf-valid', 'CPF Inválido', (value) => isValidCPF(value))
          .nullable(true),
        nr_identidade: Yup.string()
          .test({
            message: 'Necessário informar o número',
            test: (val) => !(fieldsMandatory('nr_identidade') && !val),
          })
          .matches(/^[0-9]/g, {
            message: 'Apenas números são permitidos',
            excludeEmptyString: true,
          })
          .nullable(true)
          .trim(),
        ds_orgao_emissor_ci: Yup.string()
          .trim()
          .nullable(true)
          .matches(/^[a-zA-Z]*$/g, {
            message: 'Campo só pode conter letras',
            excludeEmptyString: true,
          })
          .when('nr_identidade', {
            is: (val: string | null | undefined) => val && !!val.length,
            then: Yup.string()
              .required('Orgão emissor obrigatório')
              .matches(/^[a-zA-Z]*$/g, {
                message: 'Campo só pode conter letras',
                excludeEmptyString: true,
              })
              .nullable(true),
            otherwise: Yup.string()
              .matches(/^[a-zA-Z]*$/g, {
                message: 'Campo só pode conter letras',
                excludeEmptyString: true,
              })
              .trim()
              .nullable(true),
          }),
        nr_cert_nasc: Yup.string()
          .test({
            message: 'Certidão de nascimento é obrigatória',
            test: (val) => !(fieldsMandatory('nr_cert_nasc') && !val),
          })
          .matches(/^[A-ZÇa-zç0-9 ]*$/, {
            message: 'Campo somente letra e números',
            excludeEmptyString: true,
          })
          .nullable(true)
          .trim(),
        cep: Yup.string()
          .trim()
          .test({
            message: 'Necessário informar o CEP',
            test: (val) => !(fieldsMandatory('cep') && !val),
          })
          .nullable(true),
        sg_uf: Yup.string()
          .trim()
          .nullable(true)
          .matches(/[a-zA-Z]/g, 'Apenas caracteres são permitidos')
          .test({
            message: 'Necessário informar o UF',
            test: (val) => !(fieldsMandatory('cep') && !val),
          })
          .nullable(true),
        ds_municipio: Yup.string()
          .trim()
          .nullable(true)
          .matches(/[a-zA-Z]/g, 'Apenas caracteres são permitidos')
          .test({
            message: 'Necessário informar o município',
            test: (val) => !(fieldsMandatory('cep') && !val),
          })
          .nullable(true),
        ds_bairro: Yup.string()
          .trim()
          .nullable(true)
          .matches(/[a-zA-Z]/g, 'Apenas caracteres são permitidos')
          .test({
            message: 'Necessário informar o bairro',
            test: (val) => !(fieldsMandatory('cep') && !val),
          })
          .nullable(true),
        ds_logradouro: Yup.string()
          .trim()
          .nullable(true)
          .test({
            message: 'Necessário informar o logradouro',
            test: (val) => !(fieldsMandatory('ds_logradouro') && !val),
          })
          .nullable(true),
        nr_endereco: Yup.string()
          .trim()
          .when('semnumero', {
            is: (val: string) => val === 'N',
            then: Yup.string()
              .test({
                message: 'Necessário informar o número',
                test: (val) => !(fieldsMandatory('cep') && !val),
              })
              .matches(/^[0-9]/g, 'Apenas números são permitidos')
              .nullable(true),
            otherwise: Yup.string().nullable(true),
          }),
        semnumero: Yup.string().matches(/S|N/g, 'Campo Obrigatório'),
        ds_complemento: Yup.string()
          .trim()
          .when('semnumero', {
            is: (val: string) => val === 'S',
            then: Yup.string()
              .test({
                message: 'Necessário informar o complemento',
                test: (val) => !(fieldsMandatory('cep') && !val),
              })
              .nullable(true),
            otherwise: Yup.string().nullable(true),
          }),
        ds_email: Yup.string()
          .trim()
          .nullable(true)
          .email('Digite um e-mail válido')
          .test({
            message: 'Necessário informar o e-mail',
            test: (val) => !(fieldsMandatory('ds_email') && !val),
          })
          .nullable(true),
        confirmar_email: Yup.string()
          .trim()
          .when('ds_email', {
            is: (val: string | null | undefined) => val && !!val.length,
            then: Yup.string()
              .email('Digite um e-mail válido')
              .required('Confirmação de e-mail obrigatório')
              .nullable(true),
            otherwise: Yup.string().nullable(true),
          })
          .oneOf(
            [Yup.ref('ds_email')],
            'E-mail e confirmação precisam ser iguais.'
          )
          .nullable(true),
        nr_celular: Yup.string()
          .trim()
          .test({
            message: 'Necessário informar o celular',
            test: (val) => !(fieldsMandatory('nr_celular') && !val),
          })
          .nullable(true),
        ie_whatsapp: Yup.string()
          .trim()
          .matches(/S|N/g, 'Campo Obrigatório')
          .nullable(true),
        ie_notifica_noticias: Yup.string().matches(/S|N/g, 'Campo Obrigatório'),
        ie_notifica_whatsapp: Yup.string().matches(/S|N/g, 'Campo Obrigatório'),
        ie_notifica_dicas: Yup.string().matches(/S|N/g, 'Campo Obrigatório'),
        dados_verdadeiros: Yup.string().matches(
          /S/g,
          'Necessário confirmar a declaração'
        ),
      },
      [['ds_orgao_emissor_ci', 'nr_identidade']]
    );

  const [userDataX, setUserDataX] = useState<UserMember>(userData);

  const [selectedFile, setSelectedFile] = useState<any>();
  const [ieNotificaWhatsApp, setIeNotificaWhatsApp] = useState(
    userDataX?.ie_notifica_whatsapp || 'N'
  );
  const [arrayGender, setArrayGender] = useState<any[]>(() => {
    const returnArrayGender = getDomainsData('543').map((dominio) => ({
      label: dominio.ds_dominio_valor,
      value: dominio.vl_dominio,
    }));
    returnArrayGender.unshift(defaultArray[0]);
    return returnArrayGender;
  });
  const [arrayConheceu, setArrayConheceu] = useState<any[]>(() => {
    const returnArrayConheceu = getDomainsData('616').map((dominio) => ({
      label: dominio.ds_dominio_valor,
      value: dominio.vl_dominio,
    }));
    returnArrayConheceu.unshift(defaultArray[0]);
    return returnArrayConheceu;
  });

  const [arrayParentage, setArrayParentage] = useState([
    { label: 'Selecione', value: '' },
    { label: 'Primo(a)', value: '1' },
    { label: 'Cunhado(a)', value: '2' },
    { label: 'Filho(a)', value: '3' },
    { label: 'Cônjuge/Companheira', value: '4' },
    { label: 'Mãe', value: '5' },
    { label: 'Pai', value: '6' },
    { label: 'Irmã(o)', value: '7' },
    { label: 'Outros', value: '8' },
    { label: 'Avô(ó)', value: '9' },
    { label: 'Tio(a)', value: '10' },
    { label: 'Sobrinho(a)', value: '11' },
  ]);

  function handleParentage(event: ChangeEvent<HTMLSelectElement>) {
    const array = arrayParentage.findIndex(
      (id) => id.value === event.target.value
    );

    if (arrayParentage[array].label === 'Selecione') {
      setParentage('');
      return;
    }

    setParentage(arrayParentage[array].label);
  }

  const [parentage, setParentage] = useState<string>(() => {
    const array = arrayParentage.findIndex(
      (id) => id.value === userData?.ie_grau_parentesco
    );

    if (arrayParentage[array]?.label === 'Selecione') {
      return '';
    }

    return arrayParentage[array]?.label || '';
  });

  const [isLoading, setLoading] = useState(false);

  React.useEffect(() => {
    if (location?.state === 'new-attendance-incomplete-data') {
      firstTime.current = true;
      formRef.current?.submitForm();
    }
  }, []);

  const handleRoute = (dashboard?: boolean) => {
    let r;
    if (user && location?.state === 'new-attendance') {
      r = '/select-to-attendance';
    } else if (location?.state === 'new-attendance-incomplete-data') {
      r = '/types-care';
      if (dashboard) r = '/dashboard';
    } else if (location?.state === 'select-dependents') {
      r = '/select-dependents';
    } else if (type === 'family') {
      r = '/family-group';
    } else {
      r = '/profile';
    }

    return r;
  };

  async function handleSubmit(data: any) {
    let obrigatoryField = false;

    try {
      setDataNull(data);

      formRef.current?.setErrors({});

      handleConcatUserData(data);

      data.nr_ddi_celular = '55';

      if (data.nr_celular) {
        data.nr_celular = onlyNumbers(data.nr_celular);
        data.nr_ddd_celular = data.nr_celular.substring(0, 2);
        data.nr_celular = data.nr_celular.substring(2);
      }

      if (data.ds_email && data.confirmar_email && type !== 'family') {
        data.ds_email = data.ds_email.toLowerCase();
        data.confirmar_email = data.confirmar_email.toLowerCase();
      }
      if (
        (type === 'user' &&
          (valueParam(applicationData, 210) === 'S' ||
            fieldsMandatory('url_img')) &&
          !selectedFile &&
          !userData?.url_img) ||
        (type === 'family' &&
          (valueParam(applicationData, 211) === 'S' ||
            fieldsMandatory('url_img')) &&
          !selectedFile &&
          !userData?.url_img)
      ) {
        if (pictureRef.current) {
          pictureRef.current.scrollIntoView();
        }

        addToast({
          type: 'error',
          title: 'Ops!',
          description: 'Deve ser   a foto antes de salvar',
        });
        return;
      }

      if (
        type === 'family' &&
        !data.nr_cert_nasc &&
        !data.nr_cpf &&
        !data.nr_identidade
      ) {
        obrigatoryField = true;
        throw new Error(
          `Para ${parentage} o CPF, RG ou Certidão de Nascimento deve ser preenchido!`
        );
      }
      // Faz as validações definidas dentro de schema
      await schema.validate(data, { abortEarly: false });

      Object.keys(data).forEach((key) => {
        if (typeof data[key] === 'string') {
          data[key] = data[key].trim();
        }
      });

      const allDatas = new FormData();

      if (type === 'family') {
        allDatas.append('ie_grau_parentesco', data.ie_grau_parentesco);
        allDatas.append('nr_cert_nasc', data.nr_cert_nasc);
      }
      if (type === 'user') {
        allDatas.append('nm_social', data.nm_social);
        allDatas.append('ie_notifica_noticias', data.ie_notifica_noticias);
        allDatas.append('ie_notifica_dicas', data.ie_notifica_dicas);
        allDatas.append(
          'ie_notifica_informacoes',
          data.ie_notifica_informacoes
        );
      }
      allDatas.append('nm_pessoa', data.nm_pessoa);
      allDatas.append('ie_sexo', data.ie_sexo);
      allDatas.append('ie_como_conheceu', data.ie_como_conheceu);
      allDatas.append('dt_nascimento', data.dt_nascimento);
      allDatas.append('nm_mae', data.nm_mae);
      allDatas.append('nm_pai', data.nm_pai);
      allDatas.append('nr_cpf', data.nr_cpf);
      allDatas.append('nr_identidade', data.nr_identidade);
      allDatas.append('ds_orgao_emissor_ci', data.ds_orgao_emissor_ci);
      allDatas.append('file', selectedFile);

      allDatas.append('cep', data.cep);
      allDatas.append('sg_uf', data.sg_uf);
      allDatas.append('ds_municipio', data.ds_municipio);
      allDatas.append('ds_bairro', data.ds_bairro);
      allDatas.append('ds_logradouro', data.ds_logradouro);
      allDatas.append('ds_complemento', data.ds_complemento);
      allDatas.append(
        'nr_endereco',
        data.semnumero === 'S' ? '' : data.nr_endereco
      );
      allDatas.append('ds_email', data.ds_email);
      allDatas.append('nr_celular', data.nr_celular);
      allDatas.append('nr_ddd_celular', data.nr_ddd_celular);
      allDatas.append('nr_ddi_celular', data.nr_ddi_celular);
      allDatas.append('ie_whatsapp', data.ie_whatsapp);
      allDatas.append('ie_notifica_whatsapp', data.ie_notifica_whatsapp);

      setLoading(true);
      const resp = await requestAPI({ method, url, body: allDatas });

      setLoading(false);
      if (type !== 'family') updateUser(resp);
      addToast({
        type: 'success',
        title: 'Concluído!',
        description: 'Dados atualizados com sucesso',
      });

      history.push(handleRoute());
    } catch (err: any) {
      sendError(err);
      if (err instanceof Yup.ValidationError) {
        verifyErrorYup(err, formRef, addToast);
        setLoading(false);
      } else {
        setLoading(false);
        addToast({
          type: 'error',
          title: `${obrigatoryField ? 'Campo Obrigatório!' : 'Ops!'}`,
          description: err.message,
        });
      }
    }
  }

  async function handleSubmitPic(file: any) {
    if (method === 'POST') {
      setSelectedFile(file);
      return;
    }

    if (userDataX && file) {
      const body = new FormData();
      body.append('file', file);
      setSelectedFile(file);

      try {
        let urlPut = `/tmUsuarioFoto/${userDataX.idtm_usuario}`;
        if (type === 'family')
          urlPut = `/tmUsuarioGrupoFamiliarFoto/${userDataX?.idtm_usuario_grupo_familiar}`;

        const resp = await requestAPI({ method: 'PUT', url: urlPut, body });
        if (type !== 'family') updateUser(resp);

        setUserDataX((prev: any) => {
          handleConcatUserData(resp);
          return { ...prev, url_img: resp.url_img };
        });
        addToast({
          type: 'success',
          title: 'Concluído!',
          description: 'Foto atualizada com sucesso',
        });
      } catch (err: any) {
        sendError(err);
        addToast({
          type: 'error',
          title: 'Ops!',
          description: err.message,
        });
      }
    }
  }

  function handlePictureText() {
    if (method === 'POST' && !selectedFile) {
      return (
        <PictureText>
          {`Informe uma foto recente${
            type === 'family' && parentage ? ` - ${parentage}` : ''
          }`}
        </PictureText>
      );
    }
    return <> </>;
  }

  function handleToggleWhatsapp(userNewData: any) {
    setUserDataX(userNewData);
    setIeNotificaWhatsApp(userNewData?.ie_notifica_whatsapp || 'N');
  }

  return (
    <>
      <Container ref={pictureRef}>
        <BoxPicture margin="15px">
          <BoxPictureImg>
            <DropZone
              onFileUploaded={setSelectedFile}
              url_img={userDataX?.url_img}
              border_color="#4156a6"
              getImage={handleSubmitPic}
              hideOtherElements={() => {}}
            />
          </BoxPictureImg>
          {handlePictureText()}
        </BoxPicture>
      </Container>

      <Form
        ref={formRef}
        onSubmit={handleSubmit}
        initialData={userDataX}
        autoComplete="new-password"
      >
        <Container>
          <PageTitle
            style={{
              color: '#c00',
              textAlign: 'left',
              fontSize: 16,
            }}
          >
            * Campo obrigatório
          </PageTitle>
          <CardBlank className="pt-3">
            <PageTitle>
              {type === 'family'
                ? 'Dados Pessoais do dependente(a)'
                : 'Dados Pessoais'}
            </PageTitle>
            <Fields>
              {type === 'family' && (
                <Row className="m-0">
                  <Col md={12} xl={3}>
                    <DrSelect
                      icon="wc"
                      name="ie_grau_parentesco"
                      mandatory={fieldsMandatory('ie_grau_parentesco')}
                      disabled={!!userDataX?.ie_grau_parentesco}
                      options={arrayParentage}
                      selectTitle="Grau parentesco"
                      value={userDataX?.ie_grau_parentesco}
                      onChange={handleParentage}
                    />
                  </Col>
                </Row>
              )}
              <Row className="m-0">
                <Col md={12} xl={6}>
                  <Input
                    icon
                    name="nm_pessoa"
                    mandatory={fieldsMandatory('nm_pessoa')}
                    label={`Nome Completo${
                      type === 'family' && parentage ? ` - ${parentage}` : ''
                    }`}
                    disabled={!!userDataX?.nm_pessoa}
                    placeholder={`Digite nome completo${
                      type === 'family' && parentage ? ` - ${parentage}` : ''
                    }`}
                    value={userDataX?.nm_pessoa}
                    customType="namePessoa"
                    validate
                  />
                </Col>

                {userDataX && type === 'user' && (
                  <Col md={12} xl={6}>
                    <Input
                      icon
                      name="nm_social"
                      customType="nameInput"
                      label="Nome Social"
                      placeholder="Como deseja ser chamado?"
                      value={userDataX?.nm_social}
                    />
                  </Col>
                )}
                <Col md={12} xl={3}>
                  <DrSelect
                    icon="wc"
                    name="ie_sexo"
                    mandatory={fieldsMandatory('ie_sexo')}
                    disabled={!!userDataX?.ie_sexo}
                    options={arrayGender}
                    selectTitle={`Gênero${
                      type === 'family' && parentage ? ` - ${parentage}` : ''
                    }`}
                    value={userDataX?.ie_sexo}
                  />
                </Col>

                <Col md={12} xl={3}>
                  <Input
                    icon
                    name="dt_nascimento"
                    mandatory={fieldsMandatory('dt_nascimento')}
                    label={`Dt. Nascimento${
                      type === 'family' && parentage ? ` - ${parentage}` : ''
                    }`}
                    value={userDataX?.dt_nascimento}
                    customType="dateText"
                    validate
                    getValue={(value) => {
                      setUserDataX((prevState) => ({
                        ...prevState,
                        dt_nascimento: value,
                      }));
                    }}
                  />
                </Col>
              </Row>

              <Row className="m-0">
                <Col md={12} xl={3}>
                  <Input
                    icon
                    name="nr_cpf"
                    label={`CPF${
                      type === 'family' && parentage ? ` - ${parentage}` : ''
                    }`}
                    mandatory={fieldsMandatory('nr_cpf')}
                    disabled={!!userDataX?.nr_cpf}
                    placeholder="000.000.000-00"
                    value={userDataX?.nr_cpf}
                    customType="cpf"
                    validate
                  />
                </Col>
                <Col md={12} xl={3}>
                  <Input
                    icon
                    name="nr_identidade"
                    label={`RG${
                      type === 'family' && parentage ? ` - ${parentage}` : ''
                    }`}
                    mask="onlyNumbers"
                    placeholder={`Digite o RG${
                      type === 'family' && parentage ? ` - ${parentage}` : ''
                    }`}
                    value={userDataX?.nr_identidade}
                    validate
                    getValue={(val) => {
                      setUserDataX((prev) => ({ ...prev, nr_identidade: val }));
                      if (!val) {
                        formRef.current?.setFieldValue(
                          'ds_orgao_emissor_ci',
                          ''
                        );
                        setUserDataX((prev) => ({
                          ...prev,
                          ds_orgao_emissor_ci: '',
                        }));
                      }
                    }}
                  />
                </Col>
                <Col md={12} xl={3}>
                  <Input
                    icon
                    name="ds_orgao_emissor_ci"
                    label={`Orgão Emissor${
                      type === 'family' && parentage ? ` - ${parentage}` : ''
                    }`}
                    placeholder="Ex: SSPGO"
                    mask="nameInput"
                    value={userDataX?.ds_orgao_emissor_ci}
                    customType="orgaoEmissor"
                    disabled={
                      !!(
                        !userDataX?.ds_orgao_emissor_ci &&
                        !userDataX?.nr_identidade
                      )
                    }
                    getValue={(val) => {
                      setUserDataX((prev) => ({
                        ...prev,
                        ds_orgao_emissor_ci: val,
                      }));
                    }}
                    clear={!userDataX?.nr_identidade}
                  />
                </Col>

                {type === 'family' && (
                  <Col md={12} xl={3}>
                    <Input
                      icon
                      name="nr_cert_nasc"
                      label={`Certidão de nascimento${
                        type === 'family' && parentage ? ` - ${parentage}` : ''
                      }`}
                      placeholder="Certidão de Nascimento"
                      value={userDataX?.nr_cert_nasc}
                      validate
                    />
                  </Col>
                )}
              </Row>

              <Row className="m-0">
                <Col md={12} xl={6}>
                  <Input
                    icon
                    name="nm_mae"
                    label={
                      type === 'family'
                        ? `Nome da Mãe deste dependente`
                        : 'Nome da Mãe'
                    }
                    mandatory={fieldsMandatory('nm_mae')}
                    disabled={!!userDataX?.nm_mae}
                    placeholder={
                      type === 'family'
                        ? `Digite nome da Mãe deste dependente`
                        : 'Digite nome da Mãe'
                    }
                    value={userDataX?.nm_mae}
                    customType="namePessoa"
                    validate
                  />
                </Col>
                <Col md={12} xl={6}>
                  <Input
                    icon
                    name="nm_pai"
                    mandatory={fieldsMandatory('nm_pai')}
                    label={
                      type === 'family'
                        ? `Nome do Pai deste dependente`
                        : 'Nome do Pai'
                    }
                    placeholder={
                      type === 'family'
                        ? `Digite nome do Pai deste dependente`
                        : 'Digite nome do Pai'
                    }
                    value={userDataX?.nm_pai}
                    customType="namePessoa"
                    validate
                  />
                </Col>
              </Row>
            </Fields>
          </CardBlank>
        </Container>
        <>
          {type === 'user' ||
          (type === 'family' && memberMajority()) ||
          fieldsMandatory('cep') ? (
            <CardAddress
              userData={userDataX}
              type={type}
              parentage={parentage}
              formRef={formRef}
              addToast={addToast}
              handleAddress={handleAddress}
              sendError={sendError}
              method={method}
              fieldsMandatory={fieldsMandatory}
            />
          ) : (
            <></>
          )}
          {type === 'user' ||
          (type === 'family' && memberMajority()) ||
          fieldsMandatory('ds_email') ||
          fieldsMandatory('nr_celular') ? (
            <CardContact
              userData={userDataX}
              type={type}
              parentage={parentage}
              formRef={formRef}
              sendError={sendError}
              fieldsMandatory={fieldsMandatory}
              toggleWppNotification={handleToggleWhatsapp}
            />
          ) : (
            <></>
          )}
        </>

        {userDataX && type === 'user' && (
          <Container>
            <CardBlank className="pt-3 mt-3">
              <PageTitle>Deseja ser notificado?</PageTitle>
              <Fields>
                <Row className="m-0">
                  <Col md={12}>
                    <Checkbox
                      name="ie_notifica_noticias"
                      label={labelCheckBox('Notícias')}
                      initialValue={userDataX.ie_notifica_noticias}
                    />
                  </Col>
                  <Col md={12}>
                    <Checkbox
                      name="ie_notifica_dicas"
                      label={labelCheckBox('Dicas de Saúde')}
                      initialValue={userDataX.ie_notifica_dicas}
                    />
                  </Col>
                  <Col md={12}>
                    <Checkbox
                      name="ie_notifica_informacoes"
                      label={labelCheckBox('Novidades')}
                      initialValue={userDataX.ie_notifica_informacoes}
                    />
                  </Col>
                  <Col md={12}>
                    <Checkbox
                      name="ie_notifica_whatsapp"
                      label={labelCheckBox('Receber Notificações por WhatsApp')}
                      initialValue={userDataX.ie_notifica_whatsapp}
                      controller={[ieNotificaWhatsApp, setIeNotificaWhatsApp]}
                      disabled={userDataX?.ie_whatsapp === 'N'}
                    />
                  </Col>
                </Row>
              </Fields>
            </CardBlank>
          </Container>
        )}
        {(userDataX && userDataX.ie_como_conheceu) || type === 'family' ? (
          <></>
        ) : (
          <Container>
            <CardBlank className="pt-3  mt-3">
              <PageTitle>Como conheceu o Dr+?</PageTitle>
              <Fields>
                <Row className="m-0 justify-content-center">
                  <Col xl={3} md={12}>
                    <DrSelect
                      name="ie_como_conheceu"
                      options={arrayConheceu}
                      selectTitle=" "
                      value={userDataX?.ie_como_conheceu}
                    />
                  </Col>
                </Row>
              </Fields>
            </CardBlank>
          </Container>
        )}

        <Container>
          <CardBlank className="pt-3  mt-3">
            <PageTitle>Declaração</PageTitle>
            <Fields>
              <Row className="m-0">
                <Col xl={12}>
                  <Checkbox
                    name="dados_verdadeiros"
                    label="Declaro que todas as informações fornecidas por mim são verdadeiras."
                  />
                </Col>
              </Row>
            </Fields>
          </CardBlank>
        </Container>
        {userDataX && type === 'user' && (
          <BoxLoginBody className="pt-3">
            {(userDataX?.ie_facebook !== 'S' ||
              userDataX?.ie_google !== 'S' ||
              userDataX?.ie_apple !== 'S') && (
              <PageTitle>Vincule com suas redes sociais</PageTitle>
            )}
            <DrLoginSocial
              showFacebook={userDataX.ie_facebook !== 'S'}
              showGoogle={userDataX.ie_google !== 'S'}
              showApple={userDataX.ie_apple !== 'S'}
              idtm_usuario={userDataX?.idtm_usuario}
              setUserData={setUserDataX}
              loading={setLoading}
              linkSocial
            />
          </BoxLoginBody>
        )}

        <Container className="mt-3">
          <Row
            className="col-xl-12 justify-content-center row-btn m-0 p-0"
            style={{ marginLeft: 0, marginRight: 0 }}
          >
            <Col xl={4} className="p-0">
              <DrButtonLoading
                spaceBetween
                type="submit"
                color="blue"
                title="Salvar"
                loading={isLoading}
              />
            </Col>
            <Col xl={4} className="p-0">
              <Button
                spaceBetween
                type="button"
                href={handleRoute(
                  location?.state === 'new-attendance-incomplete-data'
                )}
                color="dark-grey"
                title="Cancelar"
              />
            </Col>
          </Row>
        </Container>
      </Form>
    </>
  );
};

export default UserData;
