import styled from 'styled-components';

interface ContainerProps {
  direction?: string;
  color?: string;
  screen?: string;
  Align?: number;
}

export const PreviewScheduleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between; /* Distribui os elementos ao longo da largura */
  align-items: center; /* Alinha os elementos verticalmente ao centro */
  width: 100%; /* Ajuste conforme necessário */
  padding: 3px; /* Ajuste conforme necessário */
  @media screen and (max-width: 376px) {
    padding: 0;
  }
`;
export const PreviewScheDiv = styled.div<ContainerProps>`
  gap: 1%;
  width: 50%;
  display: flex;
  justify-content: center;
  flex-direction: ${(props) =>
    props.direction && props.direction === 'column' ? 'row' : 'column'};
  align-items: start;
  flex-grow: ${(props) =>
    props.screen && props.screen === 'selectSpecialties' ? '1' : '0'};
  text-align: center;
  margin-left: ${(props) =>
    props.screen && props.screen === 'selectSpecialties' ? '10%' : '9%'};
  @media screen and (max-width: 990px) {
    margin-left: ${(props) =>
      props.screen && props.screen === 'selectSpecialties' ? '7%' : '0px'};
    margin-bottom: 2%;
    width: 100%;
  }
  @media screen and (max-width: 345px) {
    flex-direction: column;
  }
`;
export const ContainerImformation = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  text-align: center;
`;
export const Information = styled.h6`
  margin: 0;
  color: black;
  text-align: center;
  width: 100%;
`;
export const ScheduleContainer = styled.div<ContainerProps>`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  flex-grow: 1; /* Faz com que ocupe o espaço disponível */
  text-align: center;
  gap: 1%;
  margin-left: ${(props) =>
    props.screen && props.screen === 'selectSpecialties' ? '10%' : '0%'};
  @media screen and (max-width: 990px) {
    margin-left: ${(props) =>
      props.screen && props.screen === 'selectSpecialties' ? '7%' : '0px'};
  }
`;

export const RowDateAvaliable = styled.div<ContainerProps>`
  padding: 5px 10px;
  color: black;
  display: flex;
  flex-direction: ${(props) => (props.direction ? props.direction : 'row')};
  align-items: center;
  text-align: ${(props) =>
    props.direction && props.direction === 'column' ? 'center' : 'start'};
  justify-content: space-around;
  min-width: ${(props) =>
    props.direction && props.direction === 'column'
      ? 'none'
      : props.Align === 2
      ? '30%'
      : '45%'};
  border-radius: 5px;

  @media screen and (max-width: 1400px) {
    min-width: ${(props) =>
      props.screen &&
      props.screen === 'selectSpecialties' &&
      props.direction === 'column'
        ? '55%'
        : props.Align === 2
        ? '40%'
        : '55%'};
    padding: ${(props) =>
      props.screen && props.screen === 'selectSpecialties' ? '0px' : '5px 5px'};
    margin-left: 0%;
  }

  @media screen and (max-width: 991px) {
    min-width: ${(props) =>
      props.screen && props.screen === 'selectSpecialties' ? '40%' : '15%'};
    padding: ${(props) =>
      props.screen && props.screen === 'selectSpecialties'
        ? '0px'
        : '5px 15px'};
    margin-left: 0%;
  }
  @media screen and (max-width: 741px) {
    min-width: ${(props) =>
      props.screen &&
      props.screen === 'selectSpecialties' &&
      props.direction === 'column'
        ? '80%'
        : props.Align === 2
        ? '40%'
        : '55%'};
    padding: ${(props) =>
      props.screen && props.screen === 'selectSpecialties' ? '0px' : '5px 3px'};
    margin-left: 0%;
  }
  @media screen and (max-width: 361px) {
    min-width: ${(props) =>
      props.screen && props.screen === 'selectSpecialties' ? '80%' : '15%'};
    padding: ${(props) =>
      props.screen && props.screen === 'selectSpecialties' ? '0px' : '5px 0px'};
    margin-left: 0%;
  }
`;

export const DateAvaliable = styled.p`
  margin: 0;
  padding: 0;
  font-weight: 600;
  color: rgb(63, 86, 165);
`;
export const TimeAvaliable = styled.p`
  background-color: blue;
  border-radius: 5px;
  margin: 0;
`;

export const TitleButton = styled.h6`
  font-weight: 700;
  margin-bottom: 3px;
  color: rgb(63, 86, 165);
  @media screen and (max-width: 915px) {
    display: none;
  }
`;
export const SeeMoreButton = styled.div``;
export const ContainerButton = styled.div<ContainerProps>`
  min-width: 30px;
  display: flex;
  justify-content: flex-end; /* Alinha o componente no final da div */
  align-items: center;
  margin-left: auto; /* Empurra o componente para o final da div */
  flex-direction: column;
  color: ${(props) => (props.color ? props.color : 'black')};
  @media screen and (max-width: 415px) {
    display: ${(props) =>
      props.screen && props.screen === 'selectSpecialties' ? 'flex' : 'none'};
  }
`;
