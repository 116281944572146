"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    prettyStringify(object) {
        return JSON.stringify(object, null, 2);
    },
    assertEquals(a, b, msg) {
        if (a !== b)
            throw new Error(msg);
    },
    somenteNumeros(str) {
        let result = str;
        if (result)
            result = result.replace(/([^\d])+/gim, '');
        return result;
    },
    createHtmlSpinner({ leftDiv, topDiv, texto, antesSpinner = true, corTexto = 'black', fontSizeTexto = '1rem', leftSpinner, corSpinner = 'black', fontSizeSpinner = '1rem', boolCentralize = true, styleDiv, }) {
        let leftDivNP = leftDiv;
        let topDivNP = topDiv;
        let leftSpinnerNP = leftSpinner;
        let textSizeRem = 0;
        let fontSizeSpinnerNumber = 0;
        let fontSizeTextNumber = 0;
        if ((!leftDivNP || !topDivNP || !leftSpinnerNP) &&
            boolCentralize &&
            (!texto || (fontSizeTexto && fontSizeTexto.includes('rem'))) &&
            fontSizeSpinner &&
            fontSizeSpinner.includes('rem')) {
            if (fontSizeTexto) {
                fontSizeTextNumber = parseFloat(fontSizeTexto.substring(0, fontSizeTexto.indexOf('rem')));
            }
            else {
                fontSizeTextNumber = 0;
            }
            fontSizeSpinnerNumber = parseFloat(fontSizeSpinner.substring(0, fontSizeSpinner.indexOf('rem')));
            textSizeRem = texto ? texto.length * (fontSizeTextNumber / 2) : 0;
        }
        if (boolCentralize) {
            const adjustLeft = (textSizeRem - fontSizeSpinnerNumber) / 2;
            if (!leftDivNP) {
                if (textSizeRem === undefined) {
                    leftDivNP = 'calc(50% - 0.5rem)';
                }
                else {
                    leftDivNP = `calc(50% - ${adjustLeft}rem)`;
                }
            }
            if (!topDivNP) {
                if (textSizeRem === undefined) {
                    topDivNP = 'calc(50% - 0.5rem)';
                }
                else {
                    const adjustTop = (fontSizeTextNumber + fontSizeSpinnerNumber) / 2;
                    topDivNP = `calc(50% - ${adjustTop}rem)`;
                }
            }
            if (!leftSpinnerNP) {
                if (textSizeRem === undefined) {
                    leftSpinnerNP = '0';
                }
                else {
                    leftSpinnerNP = `${adjustLeft}rem`;
                }
            }
        }
        let tagTexto = '';
        if (texto) {
            let styleTexto = '';
            if (corTexto)
                styleTexto += `color: ${corTexto};`;
            if (fontSizeTexto)
                styleTexto += `font-size: ${fontSizeTexto};`;
            tagTexto = `<p style="${styleTexto}">${texto}</p>`;
        }
        let styleSpinner = '';
        if (leftSpinnerNP)
            styleSpinner += `left: ${leftSpinnerNP};`;
        if (corSpinner)
            styleSpinner += `color: ${corSpinner};`;
        if (fontSizeSpinner)
            styleSpinner += `font-size: ${fontSizeSpinner};`;
        let styleDivNP = '';
        if (leftDivNP) {
            styleDivNP += `left: ${leftDivNP};`;
            let maxWidth = leftDivNP;
            if (maxWidth.includes('calc') && maxWidth.includes('-')) {
                maxWidth = maxWidth.replace('-', '+');
            }
            styleDivNP += `max-width: ${maxWidth};`;
        }
        if (styleDiv) {
            styleDivNP += `${styleDiv};`;
        }
        if (topDivNP)
            styleDivNP += `top: ${topDivNP};`;
        return `
    <div
      class="div-spinner" style="${styleDivNP}"
    >
      ${tagTexto && antesSpinner ? tagTexto : ''}
      <i class="fas fa-spinner" style="${styleSpinner}"></i>
      ${tagTexto && !antesSpinner ? tagTexto : ''}
    </div>`;
    },
    openPopup(url, target) {
        return window.open(url, target, 'resizable,scrollbars,status=no,toolbar=no,menubar=no,location=no');
    },
    encodeURIComponent(string) {
        return encodeURIComponent(string)
            .replace(/%20/gm, '+')
            .replace(/[!'()*]/gm, (c) => `%${c.charCodeAt(0).toString(16)}`);
    },
    checkConcatStrings(strStandard, strCheck, boolSpace = true) {
        return `${strStandard}${strCheck ? `${boolSpace ? ' ' : ''}${strCheck}` : ''}`;
    },
    testCpf(strCPFNotClean) {
        const strCPF = strCPFNotClean.replace(/([^\d])+/gim, '');
        if (!strCPF)
            return false;
        const CPF_BLACKLIST = [
            '00000000000',
            '11111111111',
            '22222222222',
            '33333333333',
            '44444444444',
            '55555555555',
            '66666666666',
            '77777777777',
            '88888888888',
            '99999999999',
            '12345678909',
        ];
        let soma = 0;
        let resto = 0;
        if (strCPF.length !== 11)
            return false;
        if (CPF_BLACKLIST.includes(strCPF))
            return false;
        for (let i = 1; i <= 9; i += 1) {
            soma += parseInt(strCPF.substring(i - 1, i), 10) * (11 - i);
        }
        resto = (soma * 10) % 11;
        if (resto === 10 || resto === 11)
            resto = 0;
        if (resto !== parseInt(strCPF.substring(9, 10), 10))
            return false;
        soma = 0;
        for (let i = 1; i <= 10; i += 1) {
            soma += parseInt(strCPF.substring(i - 1, i), 10) * (12 - i);
        }
        resto = (soma * 10) % 11;
        if (resto === 10 || resto === 11)
            resto = 0;
        if (resto !== parseInt(strCPF.substring(10, 11), 10))
            return false;
        return true;
    },
    transformObjToQuery(object) {
        const entriesObjQuery = object ? Object.entries(object) : undefined;
        if (!entriesObjQuery || entriesObjQuery.length === 0)
            return '';
        let query = '';
        entriesObjQuery.forEach(([key, value]) => {
            if (query) {
                query += '&';
            }
            query += this.encodeURIComponent(key);
            query += '=';
            let valueQuery = '';
            if (typeof value === 'object') {
                valueQuery = JSON.stringify(value);
            }
            else {
                valueQuery = value;
            }
            query += this.encodeURIComponent(valueQuery);
        });
        return query;
    },
    verifyQntMinCar(qntMinCar, string) {
        let correct = false;
        if (string && string.length >= qntMinCar) {
            correct = true;
        }
        return correct;
    },
    verifyMaiuMin(string) {
        let correctMaiu = false;
        let correctMin = false;
        if (string) {
            if (/[A-Z]/.test(string)) {
                correctMaiu = true;
            }
            if (/[a-z]/.test(string)) {
                correctMin = true;
            }
        }
        return { correctMin, correctMaiu };
    },
    verifyNumeros(string) {
        let correct = false;
        if (string && /\d/.test(string)) {
            correct = true;
        }
        return correct;
    },
    verifyCarEsp(string) {
        let correct = false;
        if (string && /@|#|!|%|\*|\$/.test(string)) {
            correct = true;
        }
        return correct;
    },
    emailHidden(email) {
        let emailHidden = email[0];
        const idxAtSign = email.indexOf('@');
        for (let i = 1; i < idxAtSign - 1; i += 1) {
            emailHidden += '*';
        }
        emailHidden += email[idxAtSign - 1];
        emailHidden += '@';
        emailHidden += email[idxAtSign + 1];
        const idxDot = email.indexOf('.', idxAtSign);
        for (let i = idxAtSign + 2; i < idxDot - 1; i += 1) {
            emailHidden += '*';
        }
        emailHidden += email[idxDot - 1];
        emailHidden += email.substring(idxDot);
        return emailHidden;
    },
    sleep(ms) {
        return __awaiter(this, void 0, void 0, function* () {
            yield new Promise((resolve) => {
                setTimeout(() => {
                    resolve();
                }, ms);
            });
        });
    },
};
