import React from 'react';
import { bibliReact } from 'bibli-react';
import { useHistory } from 'react-router-dom';
import Loading from '../dr-card-loading';
import ConsultationTerms from './consultation-terms';
import EquipamentTestCard from './equipament-test-card';
import { useAuth } from '../../hooks/auth';
import { useIsMounted } from '../../hooks/is-mounted';

export type screenType = 'loading' | 'consultation-terms' | 'equipament-test';

export interface ScreenProps {
  screen: screenType;
  setScreen: React.Dispatch<React.SetStateAction<screenType>>;
}

const Screen: React.FC<ScreenProps> = ({ screen, setScreen }: ScreenProps) => (
  // eslint-disable-next-line react/jsx-no-useless-fragment
  <>
    {
      {
        loading: <Loading />,
        'consultation-terms': <ConsultationTerms setScreen={setScreen} />,
        'equipament-test': <EquipamentTestCard />,
      }[screen]
    }
  </>
);

const EquipamentTest: React.FC = () => {
  const [screen, setScreen] = React.useState<screenType>('loading');
  const { user, requestAPI, sendError } = useAuth();
  const history = useHistory();
  const isMountedRef = useIsMounted();

  React.useEffect(() => {
    bibliReact
      .termsInvalids({ user, requestAPI })
      .then((invalids) => {
        if (isMountedRef.current) {
          if (invalids) {
            setScreen('consultation-terms');
          } else {
            setScreen('equipament-test');
          }
        }
      })
      .catch((error: any) => {
        sendError(error);
        history.push('/dashboard');
      });
  }, []);

  return <Screen screen={screen} setScreen={setScreen} />;
};

export default EquipamentTest;
