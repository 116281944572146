import React from 'react';
import {
  handlePaginationParams,
  handlePagination,
  observerForPagination,
} from '../utils/bibli';

interface PaginateProps {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

interface RequestDataProps extends handlePaginationParams {
  elementRef: React.RefObject<HTMLElement>;
  requestAPIPagination?: boolean;
  paginate?: PaginateProps;
  isLoading: boolean;
  restartPagination?: boolean;
  setRestartPagination?: any;
  sendError: (message: any) => Promise<void>;
}

export const useRequestData = (props: RequestDataProps) => {
  const isFirstTime = React.useRef(true);
  const observerRef = React.useRef<IntersectionObserver>();

  const {
    url,
    countRef,
    setState,
    requestAPI,
    itensPerPage,
    idField,
    addToast,
    setLoading,
    setDataEnded,
    cbAfterGetData,
    insideData,
    isPublic,
    titleError,
    sort,
    elementRef,
    paginate,
    requestAPIPagination,
    isLoading,
    restartPagination,
    setRestartPagination,
    sendError,
  } = props;
  if (restartPagination) {
    countRef.current = 0;
    observerRef.current?.disconnect();
  }

  React.useEffect(() => {
    async function getData() {
      try {
        setLoading(true);
        let urlChange = url;
        if (urlChange.includes('?')) {
          urlChange += '&';
        } else {
          urlChange += '?';
        }
        urlChange += `itensPerPage=${itensPerPage}`;

        const resp = await requestAPI({
          method: 'GET',
          url: urlChange,
          pagination: requestAPIPagination,
          isPublic,
        });

        setState(resp?.data);

        countRef.current = resp?.currentPage;

        if (setDataEnded && resp?.data.length < itensPerPage) {
          setDataEnded(true);
        } else if (setDataEnded) setDataEnded(false);
      } catch (err: any) {
        sendError(err);
        addToast({
          type: 'error',
          title: 'Ops!',
          description: err.message,
        });
      } finally {
        setLoading(false);
      }
    }

    if (isFirstTime.current || restartPagination) getData();
  });

  if (!observerRef.current && elementRef.current) {
    if (paginate) {
      observerRef.current = observerForPagination(
        () =>
          handlePagination({
            countRef,
            url,
            setState,
            requestAPI,
            itensPerPage,
            idField,
            addToast,
            sendError,
            setLoading: paginate.setLoading,
            insideData,
            setDataEnded,
            sort,
            titleError,
            isPublic,
            cbAfterGetData,
          }),
        elementRef.current
      );
    }
  }

  React.useEffect(() => {
    isFirstTime.current = false;
    if (restartPagination) {
      setRestartPagination(false);
    }
  });
};
