import styled from 'styled-components';

export const View = styled.div`
  display: flex;
`;

export const PreviewScheduleContainer = styled.div`
  display: flex;
  justify-content: flex-end; /* Distribui os elementos ao longo da largura */
  align-items: center; /* Alinha os elementos verticalmente ao centro */
  width: 100%; /* Ajuste conforme necessário */
  padding: 3px; /* Ajuste conforme necessário */
`;

export const ContainerMemberData = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 45%;

  @media screen and (max-width: 990px) {
    width: 100%;
  }
`;


export const ScheduleContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  flex-grow: 1; /* Faz com que ocupe o espaço disponível */
  text-align: center;
  margin-left: 7%;
`;

export const RowDateAvaliable = styled.div`
  padding: 5px 10px;
  background-color: white;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: start;
  justify-content: space-between;
  width: 140px;
  border: solid 1px black;
  border-radius: 5px;
`;

export const DateAvaliable = styled.p`
  margin: 0;
  padding: 0;
`;

export const TimeAvaliable = styled.p`
  margin: 0;
  padding: 0;
`;

export const ContainerButton = styled.div`
  display: flex;
  justify-content: flex-end; /* Alinha o componente no final da div */
  align-items: center;
  margin-left: auto; /* Empurra o componente para o final da div */
  flex-direction: column;
  color: black;
  text-align: center;
`;

export const TitleButton = styled.h6``;
export const SeeMoreButton = styled.div``;

export const ContainerProfessionalCard = styled.div`
  width: 90%;
`;
