import { HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';

export interface IconDefaultProps extends HTMLAttributes<HTMLElement> {
  color?: string;
  fontSize?: string;
  toggle?: boolean;
  disabled?: boolean;
}
export const IconDefault = styled.i<IconDefaultProps>`
  cursor: pointer;
  font-family: icomoon !important;
  text-align: center;
  speak: never;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;

  color: ${({ toggle, disabled, color }) =>
    toggle && disabled ? '#cc0000 !important' : color};

  font-size: ${(props) => props.fontSize};

  ${(props) =>
    props.toggle &&
    css`
      &:after {
        content: '';
        position: absolute;
        height: 0;
        width: 4px;
        background-color: #cc0000;
        border-radius: 10px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
        opacity: 0;
        transition: all 0.2s ease-in-out;
      }
    `}

  ${(props) =>
    props.toggle &&
    props.disabled &&
    css`
      &:after {
        height: 80px;
        opacity: 1;
      }

      @media (max-width: 1199px) {
        &:after {
          height: 60px;
        }
      }
    `}

  &.icon-add:before {
    content: '';
  }

  &.icon-alert:before {
    content: '';
  }

  &.icon-arrow-down:before {
    content: '';
  }

  &.icon-arrow-left:before {
    content: '';
  }

  &.icon-arrow-right:before {
    content: '';
  }

  &.icon-arrow-up:before {
    content: '';
  }

  &.icon-attachment:before {
    content: '';
  }

  &.icon-avatar:before {
    content: '';
  }

  &.icon-battery-level:before {
    content: '';
  }

  &.icon-bubble-chat:before {
    content: '';
  }

  &.icon-calendar:before {
    content: '';
  }

  &.icon-call:before {
    content: '';
  }

  &.icon-call-center-agent:before {
    content: '';
  }

  &.icon-camera:before {
    content: '';
  }

  &.icon-caret-down:before {
    content: '';
  }

  &.icon-caret-left:before {
    content: '';
  }

  &.icon-caret-right:before {
    content: '';
  }

  &.icon-caret-top:before {
    content: '';
  }

  &.icon-circle-arrow-down:before {
    content: '';
  }

  &.icon-circle-arrow-left:before {
    content: '';
  }

  &.icon-circle-arrow-right:before {
    content: '';
  }

  &.icon-circle-arrow-up:before {
    content: '';
  }

  &.icon-close:before {
    content: '';
  }

  &.icon-connection:before {
    content: '';
  }

  &.icon-credit-card:before {
    content: '';
  }

  &.icon-dislike:before {
    content: '';
  }

  &.icon-dr:before {
    content: '';
  }

  &.icon-email:before {
    content: '';
  }

  &.icon-envelope:before {
    content: '';
  }

  &.icon-favorite:before {
    content: '';
  }

  &.icon-file:before {
    content: '';
  }

  &.icon-group:before {
    content: '';
  }

  &.icon-home:before {
    content: '';
  }

  &.icon-identity-card:before {
    content: '';
  }

  &.icon-like:before {
    content: '';
  }

  &.icon-logo-google:before {
    content: '';
  }

  &.icon-logout:before {
    content: '';
  }

  &.icon-mais:before {
    content: '';
    display: flex;
    line-height: 0;
    @keyframes spinner {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
    animation: spinner 1500ms infinite linear;
  }

  &.icon-microphone:before {
    content: '';
  }

  &.icon-mute:before {
    content: '';
  }

  &.icon-notification:before {
    content: '';
  }

  &.icon-not-visible:before {
    content: '';
  }

  &.icon-padlock:before {
    content: '';
  }

  &.icon-pdf-file:before {
    content: '';
  }

  &.icon-refresh:before {
    content: '';
  }

  &.icon-searching:before {
    content: '';
  }

  &.icon-send:before {
    content: '';
  }

  &.icon-setting:before {
    content: '';
  }

  &.icon-video:before {
    content: '';
  }

  &.icon-visible:before {
    content: '';
  }

  &.icon-volume-alto:before {
    content: '';
  }

  &.icon-wc:before {
    content: '';
  }

  &.icon-wifi:before {
    content: '';
  }

  &.icon-write:before {
    content: '';
  }

  &.icon-zoom:before {
    content: '';
  }
  &.icon-undo2:before {
    content: '';
  }

  &.icon-trash-o:before {
    content: '';
  }

  &.icon-heart:before {
    content: '';
  }

  &.icon-heart-o:before {
    content: '';
  }

  &.icon-group-discussion:before {
    content: '';
  }

  &.icon-group2:before {
    content: '';
  }

  &.icon-health:before {
    content: '';
  }

  &.icon-bookmarks:before {
    content: '';
  }

  &.icon-upload3:before {
    content: '';
  }

  &.icon-delete-profile:before {
    content: '';
  }

  &.icon-copy:before {
    content: '';
  }

  &.icon-checklist:before {
    content: '';
  }
`;
