"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    getErrorApi(error) {
        if (error.message)
            return error.message;
        return error;
    },
    replaceAll(str, de, para) {
        let aux = para;
        let result = str;
        if (str) {
            if (!para)
                aux = '';
            let pos = result.indexOf(de);
            while (pos > -1) {
                result = result.replace(de, aux);
                pos = result.indexOf(de);
            }
        }
        return result;
    },
    replaceTagsValues(text, objeto, getMacroTag, valNotFind, notReplaceNotFind) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!text)
                return null;
            let startTag = 99;
            let endTag;
            let newText = text;
            newText = this.replaceAll(newText, '@[', '');
            newText = this.replaceAll(newText, ']@', '');
            let test = 0;
            while (startTag !== -1 && test < 10000) {
                test += 1;
                let param;
                startTag = endTag
                    ? (startTag = text.indexOf('$[', endTag))
                    : (startTag = text.indexOf('$['));
                endTag = startTag !== -1 ? text.indexOf(']$', startTag) : -1;
                if (startTag === -1 || endTag === -1)
                    return newText;
                param = text.substring(startTag + 2, endTag);
                let startTrash = param.indexOf('<');
                let paramTrash = '';
                if (startTrash !== -1)
                    paramTrash = param;
                while (startTrash !== -1) {
                    const endTrash = param.indexOf('>', startTrash);
                    if (endTrash !== -1) {
                        const trash = param.substring(startTrash, endTrash + 1);
                        param = param.replace(trash, '');
                    }
                    startTrash = param.indexOf('<');
                }
                if (getMacroTag) {
                    try {
                        const result = yield getMacroTag(param, objeto);
                        if (result) {
                            newText = newText.replace(`$[${param}]$`, notReplaceNotFind && !result
                                ? `$[${param}]$`
                                : `${result || valNotFind || ''}`);
                        }
                    }
                    catch (e) {
                        console.log('Erro ao executar macro!', e);
                    }
                }
                if (paramTrash !== '') {
                    newText = newText.replace(`$[${paramTrash}]$`, notReplaceNotFind
                        ? `$[${param}]$`
                        : `${objeto[param] || valNotFind || ''}`);
                }
                else {
                    newText = newText.replace(`$[${param}]$`, notReplaceNotFind && !objeto[param]
                        ? `$[${param}]$`
                        : `${objeto[param] || valNotFind || ''}`);
                }
            }
            if (test >= 10000) {
                throw new Error('Excedido a quantidade de tentativas do While! (bibli-node.replaceTagsValues)!');
            }
            return newText;
        });
    },
};
