/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useRef, useState } from 'react';
import cardValidator from 'card-validator';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
import { Col, Container, Row } from 'react-bootstrap';
import moment from 'moment';
import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';
import getValidationCards from '../../utils/getValidationCards';
import { isCreditCardDate, isCreditCardNumber } from '../../utils/validations';
import Header from '../../components/dr-header';
import Footer from '../../components/dr-footer';
import { CardBlank } from '../../styles/card-blank';
import { PageTitle } from '../../styles/page-title';
import { Fields } from '../../styles/fields';
import { BoxCards } from '../../styles/box-cards';
import { CardCards } from '../../styles/card-cards';
import {
  handleWhatsAppMessage,
  verifyErrorYup,
  handleUseApiLog,
  valueParam,
} from '../../utils/bibli';
import { PixImg } from './styled';
import { Field } from '../../styles/field';
import Pix from '../../assets/img/pix2.png';
import Input from '../../components/dr-input';
import Checkbox from '../../components/dr-input-checkbox';
import DrSelect from '../../components/dr-input-select';
import DrButtonLoading from '../../components/dr-button-loading';
import DrCardLoading from '../../components/dr-card-loading';
import { BPLi } from '../PayOptions/card-pay-options/styled';
import { formatValue } from '../../utils/formatNumbers';
import DrButtonsFooter from '../../components/dr-buttons-footer';
import { AnchorStyled } from '../../styles/link-styled';
import DrModal from '../../components/dr-modal';

interface SuccessfulPaymentTypes {
  idconta_receber_cartao_movto: number;
  ie_situacao: string;
  ds_situacao: string;
  nr_autorizacao: string;
  ie_autorizado: string;
  idcontrato: number;
  dadosAssinaturaPln: any;
}

const defaultCard = {
  number: '',
  name: '',
  cvv: '',
  expire: '',
};

const defaultArray = [{ label: 'Selecione', value: '' }];

interface CardRenderProps {
  selectedCard: {
    idtm_usuario_cartao: number;
    ds_nrcartao: string;
    ie_forma_pg: string;
    nm_titular: string;
    ie_bandeira: string;
    ds_validade: string;
  };
  handleInputCvv(value: string): void;
  handleCallbackFlag: (issuer: any, isValid: boolean) => void;
  arrayCards: any[];
  handleOnChangeSelect: (ev: any) => void;
  isLoading: boolean;
  setIsNewCreditCard: React.Dispatch<React.SetStateAction<boolean>>;
  params: any;
  handleClearValues(): void;
}
interface NewCardRenderProps {
  handleInputNumber(value: string): void;
  handleInputName(value: string): void;
  handleInputExpire(value: string): void;
  handleInputCvv(value: string): void;
  cardData: {
    number: '';
    name: '';
    cvv: '';
    expire: '';
  };
  handleCallbackFlag: (issuer: any, isValid: boolean) => void;
}

const RenderCard: React.FC<CardRenderProps> = (Props: CardRenderProps) => {
  const {
    setIsNewCreditCard,
    selectedCard,
    handleClearValues,
    handleCallbackFlag,
    arrayCards,
    handleOnChangeSelect,
    handleInputCvv,
    isLoading,
    params,
  } = Props;
  return (
    <>
      <Row className="justify-content-center ">
        <Col md={12} xl={4}>
          <BoxCards>
            <CardCards>
              <Cards
                cvc=""
                expiry={selectedCard.ds_validade}
                name={selectedCard.nm_titular}
                number={selectedCard.ds_nrcartao}
                locale={{ valid: 'validade' }}
                acceptedCards={[
                  'visa',
                  'mastercard',
                  'discover',
                  'amex',
                  'jcb',
                  'dinersclub',
                  'elo',
                  'aura',
                ]}
                placeholders={{ name: 'Seu nome aqui' }}
                callback={handleCallbackFlag}
              />
            </CardCards>
          </BoxCards>
        </Col>
      </Row>

      <Row className="justify-content-center pt-1 pb-1 m-0">
        <Col md={12} xl={3}>
          <Field>
            <DrSelect
              name="selected_card"
              options={arrayCards}
              onChange={(ev) => handleOnChangeSelect(ev)}
              selectTitle="Selecione um cartão"
              value={selectedCard.idtm_usuario_cartao}
            />
          </Field>
        </Col>
        <Col md={12} xl={2}>
          <Field>
            <Input
              type="text"
              name="nr_cvv"
              getValue={handleInputCvv}
              label="CVV"
              placeholder="CVV"
              customType="cvc"
              inputMode="numeric"
              validate
            />
          </Field>
        </Col>
      </Row>
    </>
  );
};
const RenderNewCard: React.FC<NewCardRenderProps> = (
  Props: NewCardRenderProps
) => {
  const {
    handleInputNumber,
    handleInputName,
    handleInputExpire,
    handleInputCvv,
    cardData,
    handleCallbackFlag,
  } = Props;

  return (
    <>
      <Row className="justify-content-center ">
        <Col md={12} xl={4}>
          <BoxCards>
            <CardCards>
              <Cards
                cvc={cardData.cvv}
                expiry={cardData.expire}
                name={cardData.name}
                number={cardData.number}
                locale={{ valid: 'validade' }}
                acceptedCards={[
                  'visa',
                  'mastercard',
                  'discover',
                  'amex',
                  'jcb',
                  'dinersclub',
                  'elo',
                  'aura',
                ]}
                placeholders={{ name: 'Seu nome aqui' }}
                callback={handleCallbackFlag}
              />
            </CardCards>
          </BoxCards>
        </Col>
      </Row>
      <Row className="pt-1 m-0 align-items-center">
        <Col md={12} xl={3}>
          <Field>
            <Input
              name="ds_nrcartao"
              label="Número do Cartão"
              placeholder="•••• •••• •••• ••••"
              customType="cardNumber"
              getValue={handleInputNumber}
              inputMode="numeric"
              validate
              value={cardData.number}
            />
          </Field>
        </Col>
        <Col md={12} xl={3}>
          <Field>
            <Input
              name="nm_titular"
              label="Nome do Titular"
              getValue={handleInputName}
              placeholder="Digite o nome do titular"
              customType="nameInput"
              validate
              value={cardData.name}
            />
          </Field>
        </Col>
        <Col md={12} xl={2}>
          <Field>
            <Input
              type="text"
              name="ds_validade"
              getValue={handleInputExpire}
              label="Validade ( MM / AA )"
              placeholder="MM / AA"
              customType="cardDate"
              inputMode="numeric"
              validate
              value={cardData.expire}
            />
          </Field>
        </Col>

        <Col md={12} xl={2}>
          <Field className="mb-0">
            <Input
              type="text"
              name="nr_cvv"
              getValue={handleInputCvv}
              label="CVV"
              placeholder="CVV"
              customType="cvc"
              inputMode="numeric"
              validate
              value={cardData.cvv}
            />
          </Field>
        </Col>
        <Col md={12} xl={2} className="pr-0">
          <Field className="mb-0">
            <Checkbox
              name="ie_salvar"
              label={<>Salvar este cartão</>}
              noMargin
            />
          </Field>
        </Col>
      </Row>
    </>
  );
};

const Payment: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const couponRef = useRef<FormHandles>(null);
  const [isModalPix, setModalPix] = useState(false);
  const [quitModal, setQuitModal] = useState(false);

  const {
    updateUser,
    user,
    attendance,
    requestAPI,
    setAttendanceData,
    sendError,
    applicationData,
  } = useAuth();
  const {
    idconvenio,
    idestabelecimento_tipo_atendimento,
    idespecialidade,
    assinaturas_pln,
    idxBtnAttendance,
    ie_recorrente,
  } = attendance;

  const { params } = useRouteMatch<any>();

  const { addToast } = useToast();
  const history = useHistory();
  const [isLoading, setLoading] = useState(true);
  const [installment, setInstallment] = React.useState(
    attendance.nr_parcela || 1
  );
  const [arrayInstallments, setArrayInstallments] = React.useState<any>(
    (prev) => {
      let returnArray: any = defaultArray;
      if (attendance.opcao_parcela) {
        returnArray = attendance.opcao_parcela.map((option) => ({
          label: option.descricao,
          value: option.qtd,
        }));
      }
      return returnArray;
    }
  );
  const [modal, setModal] = React.useState({ msg: undefined });
  const [arrayCardData, setArrayCardData] = useState<any>([]);
  const [cardData, setCardData] = useState<any>(defaultCard);
  const [cardBrand, setCardBrand] = useState<string>('');
  const [isNewCreditCard, setIsNewCreditCard] = useState(false);
  const isAppliedRef = React.useRef(false);
  const [arrayCards, setArrayCards] = useState<any>(defaultArray);
  const [selectedCard, setSelectedCard] = useState<any>({});

  const [values, setValues] = React.useState({
    voucher: '',
    idproduto_serv: '',
    qtd_dias: '',
    ie_tipo_voucher: '',
    idvoucher: undefined,
    ds_forma: undefined,
    nr_parcela: attendance.nr_parcela || 1,
    vl_atendimento: attendance.vl_servico || undefined,
    vl_produto: attendance.vl_servico || undefined,
    vl_desconto: attendance.vl_desconto || 0,
    vl_pagto: attendance.vl_total_servico || undefined,
    isFree: false,
  });

  const timeOutRef = React.useRef<ReturnType<typeof setTimeout>>();

  const handleQuitPayment = () => {
    if (values && values.vl_desconto !== 0) {
      return setQuitModal(true);
    }

    return history.push(`/pay-options`);
  };

  React.useEffect(
    () => () => {
      if (timeOutRef.current) clearTimeout(timeOutRef.current);
    },
    []
  );

  React.useEffect(() => {
    requestAPI({
      method: 'GET',
      url: `/tmUsuarioCartaoUsu?idpessoa_fisica=${user.idpessoa_fisica}`,
    })
      .then((resp) => {
        if (!resp.length) {
          setIsNewCreditCard(true);
        } else {
          const result = resp.map((cartao, i) => ({
            label: cartao.ds_nrcartao,
            value: cartao.idtm_usuario_cartao,
          }));

          setArrayCards([...result] || []);
          setSelectedCard(resp.length ? resp[0] : defaultCard);
          setCardData(resp);
          setArrayCardData(resp);
        }
      })
      .catch((err: any) => {
        sendError(err);
        addToast({
          type: 'error',
          title: 'Ops!',
          description: err.message,
        });
        history.push('/types-care');
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  function setOriginalValue() {
    setValues((prev) => ({
      ...prev,
      voucher: '',
      idproduto_serv: '',
      qtd_dias: '',
      ds_forma: undefined,
      ie_tipo_voucher: '',
      nr_parcela: attendance.nr_parcela || 1,
      vl_atendimento: attendance.vl_servico || undefined,
      vl_produto: attendance.vl_servico || undefined,
      vl_desconto: attendance.vl_desconto || 0,
      vl_pagto: attendance.vl_total_servico || undefined,

      isFree: false,
    }));
    setArrayInstallments((prev) => {
      let returnArray: any = defaultArray;
      if (attendance.opcao_parcela) {
        returnArray = attendance.opcao_parcela.map((option) => ({
          label: option.descricao,
          value: option.qtd,
        }));
      }
      return [...returnArray];
    });
  }

  async function handleApplyCoupon(data) {
    try {
      const schema = Yup.object().shape({
        coupon: Yup.string().required('O Cupom deve ser preenchido').trim(),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      let url = `/tmAtendimentoValorVoucher?idconvenio=${idconvenio}`;
      url += `&idestabelecimento_tipo_atendimento=${idestabelecimento_tipo_atendimento}`;
      url += `&idpessoa_titular=${user.idpessoa_fisica}`;
      url += `&cd_voucher=${values.voucher}`;

      if (idespecialidade) url += `&idespecialidade=${idespecialidade}`;

      if (assinaturas_pln && assinaturas_pln.idproduto_serv)
        url += `&idproduto_serv=${assinaturas_pln.idproduto_serv}`;

      setLoading(true);

      const resp = await requestAPI({ method: 'GET', url });

      if (resp.opcao_parcela && resp.opcao_parcela.length) {
        setValues((prev) => ({
          ...prev,
          vl_atendimento: resp.vl_atendimento,
          vl_pagto: resp.vl_pagto,
          vl_desconto: resp.vl_desconto,
          ie_tipo_voucher: resp.ie_tipo_voucher,
          idvoucher: resp.idvoucher,
          isFree:
            (!resp.vl_pagto &&
              (!resp.qtd_periodo_contrato || resp.qtd_periodo_contrato <= 1)) ||
            (!resp.vl_pagto && resp.ie_tipo_voucher === 'S'),
        }));

        setArrayInstallments((prev) => {
          let returnArray = defaultArray;
          if (resp && resp.opcao_parcela) {
            returnArray = resp.opcao_parcela.map(
              (option: { descricao: any; qtd: any }) => ({
                label: option.descricao,
                value: option.qtd,
              })
            );
          }

          prev = [...returnArray];

          return [...prev];
        });
      } else if (resp.regra_pagto && resp.regra_pagto.length) {
        setValues((prev) => ({
          ...prev,
          vl_atendimento: resp.regra_pagto[idxBtnAttendance || 0].vl_produto,
          vl_pagto: resp.regra_pagto[idxBtnAttendance || 0].vl_pagto,
          vl_desconto: resp.regra_pagto[idxBtnAttendance || 0].vl_desconto,
          ie_tipo_voucher: resp.ie_tipo_voucher,
          isFree:
            (!resp.regra_pagto[idxBtnAttendance || 0].vl_pagto &&
              (!resp.qtd_periodo_contrato || resp.qtd_periodo_contrato <= 1)) ||
            (!resp.regra_pagto[idxBtnAttendance || 0].vl_pagto &&
              resp.ie_tipo_voucher === 'S'),
        }));
      }

      addToast({
        title: 'Sucesso',
        type: 'success',
        description: 'Cupom válido! Prossiga para finalizar.',
      });
      isAppliedRef.current = true;
    } catch (err: any) {
      sendError(err);
      if (err instanceof Yup.ValidationError) {
        verifyErrorYup(err, couponRef);
      } else {
        setOriginalValue();
      }
      addToast({
        type: 'error',
        title: 'Ops!',
        description: err.message,
      });
    } finally {
      setLoading(false);
    }
  }

  async function handleSubmit(d) {
    let data = d;
    const { agendarPaciente } = attendance;

    delete data.coupon;
    delete data.selected_card;

    data.idagenda_consulta = attendance.idagenda_consulta;
    data.assinaturas_pln = assinaturas_pln;
    data.ds_observacao = JSON.stringify(attendance);
    data.idpessoa_fisica = user.idpessoa_fisica;
    data.cd_voucher = values.voucher;
    data.ie_recorrente = ie_recorrente;
    data.vl_desconto = values.vl_desconto;
    data.vl_servico = values.vl_atendimento || values.vl_produto;
    data.vl_total_servico = values.vl_pagto;
    data.agendarPaciente = agendarPaciente;

    if (values.voucher && !isAppliedRef.current) {
      await handleApplyCoupon({ coupon: values.voucher });
      return;
    }

    let schema;
    try {
      if (timeOutRef.current) clearTimeout(timeOutRef.current);
      if (!values.isFree) {
        data.ie_forma_pg = 'C';
        data.nr_parcela = data.nr_parcela || installment;
        data.ie_bandeira = getValidationCards(cardBrand);
        data.ds_nrcartao = cardData.number;
        if (isNewCreditCard) {
          schema = Yup.object().shape({
            ds_nrcartao: Yup.string()
              .test({
                message: 'Cartão de crédito inválido',
                test: (value) => cardValidator.number(value).isValid,
              })
              .required('Cartão de crédito e obrigatório')
              .trim(),
            nm_titular: Yup.string()
              .required('Nome do titular do cartão é obrigatório')
              .trim(),
            ds_validade: Yup.string()
              .required('Data de validade do cartão e obrigatória')

              .test({
                message: 'Data informada é inválida',
                test: (value) => cardValidator.expirationDate(value).isValid,
              })
              .trim(),
            ie_bandeira: Yup.string().required('Campo obrigatório').trim(),
            nr_cvv: Yup.string().required('Cvv é obrigatório').trim(),
            ie_salvar: Yup.string()
              .matches(/S|N/g, 'Campo não informado')
              .trim(),
          });
        } else {
          schema = Yup.object().shape({
            nr_cvv: Yup.string().required('Cvv é obrigatório').trim(),
          });
          data = Object.assign(data, {
            idtm_usuario_cartao: selectedCard.idtm_usuario_cartao,
            ds_nrcartao: selectedCard.ds_nrcartao,
            ie_forma_pg: selectedCard.ie_forma_pg,
            nm_titular: selectedCard.nm_titular,
            ie_bandeira: selectedCard.ie_bandeira,
            ds_validade: selectedCard.ds_validade,
          });
        }
      }
      let schemaCheckBox;
      let schemaRequired;
      if (
        valueParam(applicationData, 198) !== -1 &&
        arrayInstallments &&
        arrayInstallments.length > 1
      ) {
        schemaCheckBox = Yup.object().shape({
          termos_aceite: Yup.string().matches(
            /S/g,
            'Necessário aceitar nossos termos de contratação'
          ),
        });
        if (schema) schemaRequired = schema.concat(schemaCheckBox);
      }

      if (
        valueParam(applicationData, 199) !== -1 &&
        !(arrayInstallments && arrayInstallments.length > 1)
      ) {
        schemaCheckBox = Yup.object().shape({
          termos_aceite: Yup.string().matches(
            /S/g,
            'Necessário aceitar nossos termos de contratação de assinatura'
          ),
        });
        if (schema) schemaRequired = schema.concat(schemaCheckBox);
      }

      if (schemaRequired) {
        await schemaRequired.validate(data, {
          abortEarly: false,
        });
      } else if (schema) {
        await schema.validate(data, {
          abortEarly: false,
        });
      } else if (schemaCheckBox) {
        await schemaCheckBox.validate(data, {
          abortEarly: false,
        });
      }

      if (isNewCreditCard && !values.isFree) {
        data.ds_validade = moment(data.ds_validade, 'MM/YY').format('MM/YYYY');
      }

      let paySuccess: SuccessfulPaymentTypes | null = null;

      setLoading(true);
      const resp = await requestAPI({
        method: 'POST',
        url: `/pagamentoCartao`,
        body: data,
      });

      paySuccess = resp as SuccessfulPaymentTypes;

      if (paySuccess?.ie_autorizado === 'S') {
        const localStorageDatas = Object.assign(attendance, {
          idconta_receber_cartao_movto: paySuccess.idconta_receber_cartao_movto,
          idcontrato: paySuccess.dadosAssinaturaPln
            ? paySuccess.dadosAssinaturaPln.assinaturas_pln.idcontrato
            : undefined,
          assinaturas_pln: paySuccess.dadosAssinaturaPln
            ? paySuccess.dadosAssinaturaPln.assinaturas_pln
            : undefined,
          vl_total_servico: values.vl_pagto,
        });

        if (paySuccess.dadosAssinaturaPln) {
          const userUp = user;
          userUp.ds_produto =
            paySuccess.dadosAssinaturaPln?.assinaturas_pln?.produto_ser?.ds_produto;
          updateUser({ ...userUp });
        }

        await setAttendanceData(localStorageDatas, true);

        setLoading(false);
        history.push({
          pathname: `/payment-made`,
          state: paySuccess,
        });
      }
    } catch (err: any) {
      sendError(err);
      if (err instanceof Yup.ValidationError) {
        verifyErrorYup(err, formRef, addToast);
        return;
      }
      if (err.data && err.data.mensagem_suporte) {
        timeOutRef.current = setTimeout(() => {
          setModal({ msg: err.data.mensagem_suporte });
        }, 3000);
      }
      setLoading(false);
      addToast({
        type: 'error',
        title: 'Ops!',
        description: err.message,
      });
    }
  }

  function handleCouponInput(value: string) {
    isAppliedRef.current = false;
    if (!value) setOriginalValue();
    setValues((prev) => ({ ...prev, voucher: value }));
  }
  function handleInputNumber(value: string) {
    setCardData((prev) => ({ ...prev, number: value }));
  }
  function handleInputName(value: string) {
    setCardData((prev) => ({ ...prev, name: value }));
  }

  function handleInputExpire(value: string) {
    setCardData((prev) => ({ ...prev, expire: value }));
  }
  function handleInputCvv(value: string) {
    setCardData((prev) => ({ ...prev, cvv: value }));
  }

  const handleCallbackFlag = useCallback((issuer: any) => {
    setCardBrand(issuer.issuer);
  }, []);

  const handleOnChangeSelect = (ev) => {
    const { value } = ev.currentTarget;
    setSelectedCard(
      arrayCardData.find(
        ({ idtm_usuario_cartao }) => idtm_usuario_cartao === parseInt(value, 10)
      )
    );
  };

  const handleSelectPaymentOptions = (ev) => {
    const { value } = ev.currentTarget;
    setInstallment(value);
  };

  function handleClearValues() {
    setCardData(defaultCard);
  }

  // parametros da função handleSendWhatsApp
  const pagamento_failed = 'Não estou conseguindo efetuar o pagamento';
  const messagePix =
    applicationData &&
    applicationData.valores_parametros &&
    applicationData.valores_parametros['307'] === 'S'
      ? 'Você escolheu pagar com PIX, deseja realmente confirmar esta opção de pagamento ?'
      : 'Para obter a chave PIX e efetuar o pagamento, deve passar pelo nosso atendimento humano via WhatsApp';
  const pix3 = `Oi, sou {nm_pessoa} e estou no {plataforma}. Quero a chave PIX para pagamento.`;

  const handleSendWhatsApp = (message, boolWppMsg) => {
    const linkWhatsapp = applicationData.valores_variaveis?.link_whatsapp;
    if (linkWhatsapp && linkWhatsapp !== '-1') {
      const res = handleWhatsAppMessage(
        linkWhatsapp,
        message,
        boolWppMsg,
        user
      );
      handleUseApiLog({
        requestAPI,
        url: window.location.href,
        action: 'WhatsApp',
        message: res,
        sendError,
      });

      const win = window.open(`${res}`, '_blank');
      setModal({ msg: undefined });
      win?.focus();
    }
  };

  return (
    <>
      <Header />

      {isLoading ? (
        <DrCardLoading className="mt-4 pt-4 pb-4" />
      ) : (
        <>
          <DrModal
            controllerModal={[!!modal.msg, setModal]}
            title="Precisando de auxílio?"
            buttons={{
              confirm: {
                title: 'Ajuda',
                onClick: () => handleSendWhatsApp(pagamento_failed, false),
              },
              cancel: {
                title: 'Agora não',
                onClick: () => setModal({ msg: undefined }),
              },
            }}
            content={<h4>{modal?.msg || ''}</h4>}
          />
          <Container className="pt-3">
            <CardBlank className="pt-2 pb-2">
              <PageTitle>Realizar Pagamento</PageTitle>
              <Fields>
                <Form ref={couponRef} onSubmit={handleApplyCoupon}>
                  <Fields>
                    <Row className="justify-content-center align-items-center pt-1 pb-1 m-0">
                      <Col xl={3}>
                        <Input
                          name="coupon"
                          label="Possui cupom ou vale?"
                          getValue={handleCouponInput}
                          value={values.voucher}
                          clear={!values.voucher}
                        />
                      </Col>
                      <Col xl={2}>
                        <DrButtonLoading
                          type="submit"
                          color="#3F56A5"
                          title="Aplicar"
                          titleColor="white"
                          loading={isLoading}
                        />
                      </Col>
                    </Row>
                  </Fields>
                </Form>
                {(values.vl_atendimento !== undefined ||
                  values.vl_produto !== undefined) && (
                  <BPLi style={{ textAlign: 'center' }}>
                    Produto: {values.ds_forma}{' '}
                    {formatValue(
                      values.vl_atendimento || values.vl_produto || '0'
                    )}
                  </BPLi>
                )}
                {values.vl_desconto !== undefined && (
                  <BPLi style={{ textAlign: 'center' }}>
                    Desconto: {formatValue(values.vl_desconto)}
                  </BPLi>
                )}
                {values.vl_pagto !== undefined && (
                  <BPLi style={{ textAlign: 'center', marginBottom: 10 }}>
                    Restante para pagamento: {formatValue(values.vl_pagto)}
                  </BPLi>
                )}

                <DrModal
                  controllerModal={[isModalPix, setModalPix]}
                  title="Pagar com Pix"
                  buttons={{
                    confirm: {
                      title: 'Confirmar',
                      onClick: () => {
                        if (
                          applicationData &&
                          applicationData.valores_parametros &&
                          applicationData.valores_parametros['307'] === 'S'
                        ) {
                          setModalPix(false);
                          history.push('/payment-pix', { values });
                        } else {
                          handleSendWhatsApp(pix3, true);
                        }
                      },
                    },
                    cancel: {
                      title: 'Voltar',
                      onClick: () => setModalPix(false),
                    },
                  }}
                  content={<h4>{modal?.msg || messagePix}</h4>}
                />

                {!values.isFree &&
                ie_recorrente !== 'S' &&
                valueParam(applicationData, 245) === 'S' ? (
                  <Row className="justify-content-center m-0">
                    <>
                      <PixImg>
                        <figure>
                          <a href="#" onClick={() => setModalPix(true)}>
                            <img
                              src={Pix}
                              alt="Pagamento Pix"
                              height={60}
                              width={180}
                            />
                            <figcaption>Pagar com PIX</figcaption>
                          </a>
                        </figure>
                      </PixImg>
                    </>
                  </Row>
                ) : (
                  <></>
                )}

                <Form ref={formRef} onSubmit={handleSubmit}>
                  {!values.isFree ? (
                    <>
                      {arrayInstallments &&
                      arrayInstallments.length > 1 &&
                      values.vl_pagto &&
                      values.vl_pagto > 0 ? (
                        <Row className="justify-content-center pt-1 pb-1 m-0">
                          <Col md={12} xl={3}>
                            <Field>
                              <DrSelect
                                name="nr_parcela"
                                selectTitle="Parcelas"
                                onChange={handleSelectPaymentOptions}
                                options={arrayInstallments}
                                initialValue={installment}
                              />
                            </Field>
                          </Col>
                        </Row>
                      ) : (
                        <></>
                      )}

                      {isNewCreditCard ? (
                        <RenderNewCard
                          handleInputNumber={handleInputNumber}
                          handleInputName={handleInputName}
                          handleInputExpire={handleInputExpire}
                          handleInputCvv={handleInputCvv}
                          cardData={cardData}
                          handleCallbackFlag={handleCallbackFlag}
                        />
                      ) : (
                        <RenderCard
                          setIsNewCreditCard={setIsNewCreditCard}
                          selectedCard={selectedCard}
                          handleClearValues={handleClearValues}
                          handleCallbackFlag={handleCallbackFlag}
                          arrayCards={arrayCards}
                          handleOnChangeSelect={handleOnChangeSelect}
                          handleInputCvv={handleInputCvv}
                          isLoading={isLoading}
                          params={params}
                        />
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                  {valueParam(applicationData, 198) !== '-1' &&
                  arrayInstallments &&
                  arrayInstallments.length > 1 ? (
                    <Row className="m-0 pt-2">
                      <Col className="d-flex justify-content-xl-center">
                        <Checkbox
                          name="termos_aceite"
                          label={
                            <>
                              Concordo com os{' '}
                              <AnchorStyled
                                href={valueParam(applicationData, 198)}
                                target="_blank"
                              >
                                Termos de Contratação
                              </AnchorStyled>{' '}
                            </>
                          }
                        />
                      </Col>
                    </Row>
                  ) : (
                    <></>
                  )}

                  {valueParam(applicationData, 199) !== '-1' &&
                  !(arrayInstallments && arrayInstallments.length > 1) ? (
                    <Row className="m-0 pt-2">
                      <Col className="d-flex justify-content-xl-center">
                        <Checkbox
                          name="termos_aceite"
                          label={
                            <>
                              Concordo com os{' '}
                              <AnchorStyled
                                href={valueParam(applicationData, 199)}
                                target="_blank"
                              >
                                Termos de contratação para assinatura
                              </AnchorStyled>{' '}
                            </>
                          }
                        />
                      </Col>
                    </Row>
                  ) : (
                    <></>
                  )}
                  {!isNewCreditCard && !values.isFree ? (
                    <Row className="justify-content-center m-0">
                      <Col xl={4}>
                        <DrButtonLoading
                          type="submit"
                          color="blue"
                          title="Pagar"
                          loading={isLoading}
                        />
                      </Col>
                    </Row>
                  ) : values.isFree ? (
                    <Row className="justify-content-center m-0">
                      <Col xl={12}>
                        <DrButtonsFooter
                          buttonLeft={{
                            type: 'submit',
                            color: 'blue',
                            title:
                              values.vl_desconto === values.vl_produto
                                ? 'Concluir'
                                : 'Prosseguir',
                            loading: isLoading,
                          }}
                          buttonRight={{
                            type: 'button',
                            color: 'dark-grey',
                            onClick: () => handleQuitPayment(),
                            title: 'Desistir',
                          }}
                        />
                      </Col>
                    </Row>
                  ) : (
                    <></>
                  )}
                  {!values.isFree ? (
                    <Container>
                      <Row className="col-xl-12 justify-content-center row-btn m-0">
                        <DrButtonsFooter
                          buttonLeft={{
                            hidden: !isNewCreditCard,
                            type: 'submit',
                            color: 'blue',
                            title: 'Efetuar pagamento',
                            loading: isLoading,
                          }}
                          buttonRight={{
                            hidden: !isNewCreditCard,
                            type: 'button',
                            color: 'dark-grey',
                            onClick: () => {
                              // eslint-disable-next-line no-unused-expressions
                              arrayCardData.length
                                ? setIsNewCreditCard(false)
                                : handleQuitPayment();
                              handleClearValues();
                            },
                            title: 'Desistir',
                          }}
                        />

                        <DrButtonsFooter
                          containerClassNm="mt-0"
                          buttonLeft={{
                            hidden: isNewCreditCard,
                            type: 'button',
                            onClick: () => {
                              setIsNewCreditCard(true);
                              handleClearValues();
                            },
                            title: 'Pagar com um novo cartão',
                            titleColor: '#3f56a5',
                            loading: isLoading,
                            color: '#f1f1f1',
                          }}
                          buttonRight={
                            attendance.assinaturas_pln?.dependentes
                              ? {
                                  hidden: isNewCreditCard,
                                  href: '/select-dependents',
                                  title: 'Desistir',
                                }
                              : {
                                  hidden: isNewCreditCard,
                                  onClick: () => handleQuitPayment(),
                                  title: 'Desistir',
                                }
                          }
                        />
                      </Row>
                    </Container>
                  ) : (
                    <></>
                  )}
                </Form>
              </Fields>
            </CardBlank>
          </Container>

          <DrModal
            controllerModal={[quitModal, setQuitModal]}
            title="Desistir"
            buttons={{
              confirm: {
                title: 'Sim',
                onClick: () => handleQuitPayment(),
              },
              cancel: {
                title: 'Não',
                onClick: () => setQuitModal(false),
              },
            }}
            content={<h4>Realmente deseja desistir do pagamento?</h4>}
          />
        </>
      )}
      <Footer />
    </>
  );
};

export default Payment;
