import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { upperCaseLetter } from '../../utils/bibli';
import {
  PreviewScheduleContainer,
  ScheduleContainer,
  RowDateAvaliable,
  DateAvaliable,
  ContainerButton,
  TitleButton,
  SeeMoreButton,
  Information,
  ContainerImformation,
  PreviewScheDiv,
} from './styled';
import DrButton from '../dr-button';
import DrIconFont from '../dr-icon-font';

const DrShowSchedulesSpecialties = ({
  specialty,
  styleGrid,
  color,
  screen,
}) => {
  const [customWidth, setCustomWidth] = useState('');
  const [specialities, setSpecialities] = useState(specialty.horarios);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);
    setCustomWidth(windowSize.width <= 380 ? '55px' : '75px');
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  useEffect(() => {
    setSpecialities(specialty.horarios);
  }, [specialty]);
  const DateComponent = (dia) => {
    const format = 'YYYY/MM/DD';

    // Normaliza a data de entrada para o formato desejado
    const diaFormatado = moment(dia, format).local().format(format);

    // Obtém o nome do dia da semana e remove "-feira" se existir (ex: "Segunda", "Terça", etc.)
    const weekDayRef = moment(diaFormatado, format)
      .local()
      .format('dddd')
      .replace('-feira', '');

    const todayFormatted = moment().local().format(format);
    const tomorrowFormatted = moment().add(1, 'days').local().format(format);

    // Verifica se é hoje ou amanhã
    if (diaFormatado === todayFormatted) {
      return 'Hoje';
    }
    if (diaFormatado === tomorrowFormatted) {
      return 'Amanhã';
    }

    // Retorna o nome do dia da semana sem "-feira"
    return weekDayRef;
  };
  return (
    <PreviewScheduleContainer>
      <PreviewScheDiv direction={styleGrid} screen={screen}>
        {specialities && specialities.length > 0 ? (
          specialities.map((especialidade) => (
            <ScheduleContainer>
              <DateAvaliable>
                {DateComponent(especialidade.dt_agenda)}
              </DateAvaliable>
              <RowDateAvaliable
                key={specialities.id}
                direction={styleGrid}
                screen={screen}
                Align={especialidade.vagas.length}
              >
                {especialidade.vagas &&
                  especialidade.vagas.map((vaga) => (
                    <DrButton
                      key={specialty.idespecialidade}
                      type="button"
                      title={moment(vaga.dt_agenda).local().format('HH:mm')}
                      color="#60bb46"
                      fontWeight="700"
                      customPadding="2px 10px"
                      customWidth={customWidth}
                      style={{ marginBottom: '18px' }}
                    />
                  ))}
              </RowDateAvaliable>
            </ScheduleContainer>
          ))
        ) : (
          <ContainerImformation>
            <Information>Sem horários disponíveis!</Information>
          </ContainerImformation>
        )}
      </PreviewScheDiv>
      <ContainerButton color={color} screen={screen}>
        <TitleButton> Ver mais</TitleButton>
        <SeeMoreButton key={specialty.idespecialidade}>
          <DrIconFont
            color="rgb(63, 86, 165)"
            name="circle-arrow-right"
            fontSize="28px"
          />
        </SeeMoreButton>
      </ContainerButton>
    </PreviewScheduleContainer>
  );
};
export default DrShowSchedulesSpecialties;
